import { Injectable } from '@angular/core';
import { CardData } from '../models';
import { PaymentType } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public cardData: CardData = {
    type: PaymentType.NONE,
  }

  constructor() {}

  public reset() {
    this.cardData.authorizenet = undefined;
    this.cardData.globalpay = undefined;
    this.cardData.tsys = undefined;
    this.cardData.fortis = undefined;
  }
}
