import { Component } from '@angular/core';
import { DataViewModule } from 'primeng/dataview';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LineItem } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { HasImagePipe } from '../../../../../pipes';
import { NgxAimAdminOrderService } from '../../ngx-aim-admin-order.service';
import { CurrencyPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-order-items',
  standalone: true,
  imports: [DataViewModule, HasImagePipe, NgClass, CurrencyPipe],
  templateUrl: './ngx-aim-admin-order-items.component.html',
  styleUrls: ['./ngx-aim-admin-order-items.component.scss']
})
export class NgxAimAdminOrderItemsComponent {
  lineItems: LineItem[] = [];

  constructor(
    private orderService: NgxAimAdminOrderService,
    private httpErrorService: HttpErrorService,
    private config: DynamicDialogConfig,
  ) {
    this.orderService.getLineItems(this.config.data).subscribe({
      next: (lineItems) => this.lineItems = lineItems,
      error: (err) => this.httpErrorService.onHttpError(err, 'Could not retrieve items'),
    });
  }
}
