import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { inject } from '@angular/core';
import { AppStateService, AuthenticationService, CartService } from '../../services';
import { PublicRoutes } from '../../enums';
import { ItemForDisplay } from '../../models';

export abstract class NgxAimCartClass {
  cartService = inject(CartService);
  messageService = inject(MessageService);
  confirmationService = inject(ConfirmationService);
  authService = inject(AuthenticationService);
  router = inject(Router);
  appState = inject(AppStateService);

  cart$ = this.cartService.cart$;
  cartTotal$ = this.cartService.cartTotal$;
  routes = PublicRoutes;

  onItemQuantityBlur($event: any, item: ItemForDisplay) {
    this.cartService.updateItemCartQuantity(item, +$event.target.value);
    if (!$event.target.value || +$event.target.value === 0) {
      this.confirmRemoveItemFromCart(item);
    }
  }

  removeItem(item: ItemForDisplay) {
    this.cartService.removeItemFromCart(item);
    this.messageService.add({
      severity: 'error',
      summary: `${item.Model} removed from cart`,
      key: 'app-toast'
    });
  }

  confirmRemoveItemFromCart(item: ItemForDisplay) {
    this.confirmationService.confirm({
      message: `Do you want to remove ${item.Model} from your cart?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeItem(item);
      },
      reject: () => {
        this.cartService.updateItemCartQuantity(item, 1);
      },
    });
  }

  onCheckoutClick() {
    if (this.authService.getCurrentUser()) {
      this.router.navigate([this.routes.CHECKOUT]);
    } else {
      this.router.navigate([this.routes.SIGN_IN], { queryParams: { returnUrl: this.routes.CHECKOUT, isCheckout: true } });
    }
  }
}