import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { AppLazyLoadEvent, CountryState, FindAllResult } from '../../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminCountryStateService extends GenericSearchService<CountryState> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/country-states`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'State');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<CountryState>> {
    return this.http.post<FindAllResult<CountryState>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<CountryState> {
    return this.http.get<CountryState>(`${this.baseUrl}/state/${id}`);
  }

  save(state: CountryState): Observable<CountryState> {
    return this.http.post<CountryState>(`${this.baseUrl}`, state);
  }
}
