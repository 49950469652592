<div>
  <div class="mb-2">
    <img src="images/others/accepted-cards.png" alt="accepted cards">
  </div>
  @if (isLoadingTsep) {
    <div styleClass="border-round-lg">
      <p-progressSpinner></p-progressSpinner>
    </div>
  }
  @else {
    <form method="POST" id="payment-form" [ngStyle]="{'display': cardData && isFinished ? 'none' : 'block'}" [attr.inert]="isObtainingToken ? '' : null">
      <div id="tsep-cardNumDiv"
           class="tsep-input"
           data-auto-formatting="Y"
           data-validate-cc="Y"
           data-detect-card-type="Y"
           [ngClass]="{'tsep-error': fieldStates['tsep-cardNum'].touched && !fieldStates['tsep-cardNum'].valid}">
      </div>
      <div id="tsep-datepickerDiv"
           class="tsep-input"
           data-validate-expiry-date="Y"
           [ngClass]="{'tsep-error': fieldStates['tsep-datepicker'].touched && !fieldStates['tsep-datepicker'].valid}">
      </div>
      <div id="tsep-cvv2Div"
           class="tsep-input"
           data-validate-cvv2="Y"
           [ngClass]="{'tsep-error': fieldStates['tsep-cvv2'].touched && !fieldStates['tsep-cvv2'].valid}">
      </div>
      <div id="tsep-cardHolderNameDiv"
           class="tsep-input"
           data-validate-name="Y"
           [ngClass]="{'tsep-error': fieldStates['tsep-cardHolderName'].touched && !fieldStates['tsep-cardHolderName'].valid}">
      </div>
      <div id="tsep-zipCodeDiv"
           class="tsep-input"
           data-validate-zipcode="Y"
           [ngClass]="{'tsep-error': fieldStates['tsep-zipCode'].touched && !fieldStates['tsep-zipCode'].valid}">
      </div>
      <p-button [disabled]="isLoadingTsep || isObtainingToken || !tsepFormValid"
                [loading]="isObtainingToken"
                (onClick)="onAddCreditCardClick()"
                label="Add Credit Card">
      </p-button>
    </form>
  }
</div>

@if (cardData && isFinished) {
  <div>
    <div class="ml-2 mb-2">
      Added {{getCardType(cardData.cardType)}} ending in: {{cardData.maskedCardNumber}}
    </div>
    <p-button (onClick)="reloadPage()" label="Change Credit Card" class="ml-2"></p-button>
  </div>
}
<p-dialog header="Please fix the following problems" [(visible)]="displayErrorDialog">
  @for (error of errors; track error) {
    <p>
      {{error}}
    </p>
  }
</p-dialog>
<p-dialog header="Error" [(visible)]="displayTsepFormError" [modal]="true" [closable]="false">
  <p>There was a problem loading the payment form. Click CONTINUE to reload the page.</p>
  <p-button (onClick)="reloadPage()" label="CONTINUE"></p-button>
</p-dialog>