import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, FindAllResult, UpdateWebCateogryDto, WebCategory } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminWebCategoryService extends GenericSearchService<WebCategory> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/web-categories`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Web category');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<WebCategory>> {
    return this.http.post<FindAllResult<WebCategory>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<WebCategory> {
    return this.http.get<WebCategory>(`${this.baseUrl}/web-category/${id}`);
  }

  save(dto: UpdateWebCateogryDto): Observable<WebCategory> {
    return this.http.post<WebCategory>(`${this.baseUrl}`, dto);
  }
}
