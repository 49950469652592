import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../ngx-aim.service';
import { Observable } from 'rxjs';
import { AppPost } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxAimPostsService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/posts`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  findAllByCategory(category: string): Observable<AppPost[]> {
    return this.http.get<AppPost[]>(`${this.baseUrl}/category/${category}`);
  }

  findOneBySlug(category: string, slug: string): Observable<AppPost> {
    return this.http.get<AppPost>(`${this.baseUrl}/category/${category}/${slug}`);
  }
}
