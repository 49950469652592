<div class="col-12 pb-2 rental-container">
  <div class="col-12 p-2 pb-5">
    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
      <span class="flex align-items-center gap-2">
        <i class="pi pi-tag"></i>
        <span class="font-semibold">{{ rentalData.subCategory.shortDescription }}</span>
      </span>
      <!-- A property could go here - top right of item box -->
    </div>
    <div class="flex flex-column align-items-center gap-3 py-5">
      <img class="shadow-2 border-round" height="300" width="300" [src]="rentalData.subCategory.thumbSrc! | hasImage" [alt]="rentalData.subCategory.webTitle" />
      <div class="text-2xl font-bold">{{ rentalData.subCategory.webTitle }}</div>
    </div>
  </div>
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12">
      @if (!isLoading) {
        @if (error) {
          {{error}}
        }
        @else {
          <span class="p-float-label">
            <p-dropdown [options]="rates" formControlName="rate" optionLabel="Desc" placeholder="Select a Frequency" inputId="rate">
              <ng-template pTemplate="selectedItem">
                @if (form.controls.rate.value) {
                  <div class="flex align-items-center gap-2">
                    <div>{{ form.controls.rate.value.Desc }} {{form.controls.rate.value.Amt | currency}}</div>
                  </div>
                }
              </ng-template>
              <ng-template let-rate pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ rate.Desc }} {{rate.Amt | currency}}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="rate">Frequency</label>
          </span>
          @if (fc.rate.touched && fc.rate.errors?.['required']) {
            <small class="p-error flex-start">This field is required.</small>
          }
        }
      }
      @else {
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
      }
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar id="startDate" [minDate]="minDate" formControlName="startDate" appendTo="body"></p-calendar>
        <label htmlFor="startDate">Pickup</label>
      </span>
      @if (fc.startDate.touched && fc.startDate.errors?.['required']) {
        <small class="p-error flex-start">This field is required.</small>
      }
      @if (showTime) {
        <ngx-aim-time-picker [isDisabled]="startTimeDisabled" (onTimeChange)="onTimeChange($event, 'startDate')"></ngx-aim-time-picker>
      }
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-calendar id="endDate" [minDate]="minDate" formControlName="endDate" appendTo="body"></p-calendar>
        <label htmlFor="endDate">Return</label>
      </span>
      @if (fc.endDate.touched && fc.endDate.errors?.['required']) {
        <small class="p-error flex-start">This field is required.</small>      
      }
      @if (showTime) {
        <ngx-aim-time-picker [isDisabled]="endTimeDisabled" (onTimeChange)="onTimeChange($event, 'endDate')"></ngx-aim-time-picker>
      }
      @if (fc.startDate.valid && fc.endDate.valid && form.errors?.['startDateBeforeEndDate']) {
        <small class="p-error flex-start">
          Return cannot be before pickup
        </small>
      }
    </div>
  </form>
  <div class="flex justify-content-end align-items-center">
    @if (isLoadingAmount) {
      <p-progressSpinner styleClass="w-2rem h-2rem mr-4" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    }
    @else {
      @if (amount) {
        <p class="text-lg mr-4">
          <strong>Est. Total: </strong>{{amount.length}} {{amount.IntervalType}} &mdash; {{amount.total | currency}}
        </p>
      }
    }

    @if (amountError) {
      <p class="mr-4">{{amountError}}</p>
    }
    <p-button [label]="actionButtonText"
              [disabled]="form.invalid || !rates.length || !isTimeSet()"
              (onClick)="onActionButtonClick()">
    </p-button>
  </div>
</div>
