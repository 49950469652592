import { inject } from '@angular/core';
import { STRRate, SubCategory } from '../../../../models';
import { ShortTermRentalService } from '../../../../services';

/** when extending this class be sure to implement ngOnInit,
 * @Input()
 * override itemPk: number | undefined;
 *
 * @Input()
 * override subCategory: SubCategory | undefined
 */
export class NgxAimRentalPriceClass {
  // ngOnInit() {
  //   this.init();
  // }

  rentalService = inject(ShortTermRentalService);

  itemPk: number | undefined;
  subCategory: SubCategory | undefined

  isLoading = true;
  rates: STRRate[] = [];
  error = '';

  init() {
    if (this.itemPk) {
      this.getItemRates(this.itemPk);
    }
    if (this.subCategory) {
      this.getSubCategoryRates(this.subCategory);
    }
  }

  getItemRates(itemPk: number) {
    this.rentalService.getRateForItem(itemPk).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.rates = res.Rates;
      },
      error: (err) => {
        this.isLoading = false;
        this.error = 'Please call for pricing';
      }
    });
  }

  getSubCategoryRates(subCategory: SubCategory) {
    this.rentalService.getRateForSubCat(subCategory).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.rates = res.Rates;
      },
      error: (err) => {
        this.isLoading = false;
        this.error = 'Please call for pricing';
      }
    });
  }
}
