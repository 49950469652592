<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Description" formControlName="Description" pTooltip="Modify in AIM" />
        <label htmlFor="Description">Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="showOnMainNav" placeholder="Show On Main Navigation"  inputId="showOnMainNav"></p-dropdown>
        <label for="showOnMainNav">Show On Main Navigation</label>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <input pInputText id="webTitle" formControlName="webTitle" />
        <label htmlFor="webTitle">Title</label>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <input pInputText id="shortDescription" formControlName="shortDescription" />
        <label htmlFor="shortDescription">Short Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <textarea  pInputTextarea formControlName="longDescription" [autoResize]="true"></textarea>
        <label htmlFor="longDescription">Long Description</label>
      </span>
    </div>
    <div class="field col-12">
      <h3 class="mb-0">Specifications</h3>
    </div>
    <ng-container formArrayName="strSpecs">
      @for (strSpec of strSpecs.controls; track strSpec; let i = $index) {
        <ng-container>
          <ng-container [formGroup]="strSpec">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <input pInputText id="Name" formControlName="name" />
                <label htmlFor="Name">Name</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <input pInputText id="Value" formControlName="value" />
                <label htmlFor="Value">Value</label>
              </span>
            </div>
            <div class="col-12 md:col-2">
              <p-button icon="pi pi-minus" styleClass="p-button-rounded" (onClick)="removeStrSpec(i)" pTooltip="Remove Specification"></p-button>        
            </div>
          </ng-container>
        </ng-container>
      }
    </ng-container>
    <div class="col-12">
      <p-button icon="pi pi-plus" styleClass="p-button-rounded" (onClick)="addStrSpec()" pTooltip="Add Specification"></p-button>
    </div>
    <div class="field col-12 md:col-12 mt-5">
      <p-divider></p-divider>
      <span>
        <h3>Image</h3>
        <p-fileUpload url="api/v1/admin/aim/category/sub-category/image"
                      name="thumbSrc"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        @if (form.controls.thumbSrc.value) {
          <div>
            <img class="mt-1" [src]="'files/' + form.controls.thumbSrc.value" width="100%" />
          </div>
        }
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
@if (isLoading) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
