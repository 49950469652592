import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { NgxAimProductGalleriaComponent } from './product-galleria/ngx-aim-product-galleria.component';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxAimRentalPriceComponent } from './rental-price/ngx-aim-rental-price.component';
import { OutOfStockPipe } from '../../../pipes';
import { NgxAimProductClass } from './ngx-aim-product.class';

@Component({
  selector: 'ngx-aim-product',
  standalone: true,
  imports: [
    NgxAimProductGalleriaComponent,
    ButtonModule,
    ProgressSpinnerModule,
    NgxAimRentalPriceComponent,
    OutOfStockPipe,
    CurrencyPipe,
  ],
  templateUrl: './ngx-aim-product.component.html',
  styleUrls: ['./ngx-aim-product.component.scss'],
  providers: [DialogService],
})
export class NgxAimProductComponent extends NgxAimProductClass implements OnInit {
  ngOnInit() {
    this.init();
  }
}
