import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { Observable } from 'rxjs';
import { ShipEngineAddressValidateResponse, ShipEngineAddressDto } from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAddressService {
  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
  ) {}

  verifyAddress(dto: ShipEngineAddressDto): Observable<ShipEngineAddressValidateResponse> {
    return this.http.post<ShipEngineAddressValidateResponse>(`${this.ngxAimService.getApiUrl()}/shipping/ship-engine/validate-address`, dto);
  }
}
