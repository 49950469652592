import { Component, Input, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { HasImagePipe } from '../../../pipes';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { NgxAimOverviewRentalsClass } from './ngx-aim-overview-rentals.class';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-overview-rentals',
  standalone: true,
  imports: [
    ButtonModule,
    DataViewModule,
    DividerModule,
    DialogModule,
    CardModule,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
  ],
  templateUrl: './ngx-aim-overview-rentals.component.html',
  styleUrls: ['./ngx-aim-overview-rentals.component.css']
})
export class NgxAimOverviewRentalsComponent extends NgxAimOverviewRentalsClass implements OnInit {
  @Input({ required: true })
  override completeUrl: string = '';

  ngOnInit() {
    this.init();
  }
}
