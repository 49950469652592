import { inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NgxAimFflSelectorService } from '../ngx-aim-ffl-selector.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpErrorService } from '../../../../services/http-error.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CheckoutService } from '../../../../services';
import { distinctUntilChanged } from 'rxjs';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimAddFflClass {
  // ngOnInit() {
  //   this.init();
  // }

  fb = inject(NonNullableFormBuilder);
  ngxFflService = inject(NgxAimFflSelectorService);
  dialogRef = inject(DynamicDialogRef);
  httpErrorService = inject(HttpErrorService);
  checkoutService = inject(CheckoutService);

  form = this.fb.group({
    name: this.fb.control<string>('', [Validators.required]),
    address1: this.fb.control<string>('', [Validators.required]),
    address2: this.fb.control<string>(''),
    city: this.fb.control<string>('', [Validators.required]),
    state: this.fb.control<string>({ value: 'OH', disabled: true }, [Validators.required]),
    zip: this.fb.control<string>('', [Validators.required]),
    phone: this.fb.control<string>('', [Validators.required]),
    fflNumber: this.fb.control<string>(''),
  });

  userAddress$ = this.checkoutService.userBillingAddress$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  isSaving = false;

  init() {
    this.userAddress$.subscribe(address => {
      address.State && this.form.controls.state.setValue(address.State);
    });
  }

  onAddClick() {
    if (!this.form.controls.state.value) {
      return;
    }

    this.isSaving = true;
    this.ngxFflService.create(this.form.getRawValue()).subscribe({
      next: (ffl) => this.dialogRef.close(ffl),
      error: (err) => { this.httpErrorService.onHttpError(err, 'Could not add FFL. Please try again'); this.isSaving = false; }
    });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  get fc() { return this.form.controls; }
}