import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthnetConfig } from './authorizenet.model';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAuthorizenetService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/payment/authorizenet`;

  constructor(private ngxAimService: NgxAimService, private http: HttpClient) {}

  getConfig(): Observable<AuthnetConfig> {
    return this.http.get<AuthnetConfig>(`${this.baseUrl}/config`);
  }
}
