import { Component, OnInit } from '@angular/core';
import { AppPost } from '../../../models';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'ngx-aim-post',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './ngx-aim-post.component.html',
  styleUrl: './ngx-aim-post.component.css'
})
export class NgxAimPostComponent implements OnInit {
  post?: AppPost;

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
  ) {}

  ngOnInit() {
    this.post = this.config.data.post;
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
