import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { ContractsService, HttpErrorService } from '../../services';
import { C2BDistrictGeneric, C2BSchoolGeneric, C2BTeacherGeneric } from '../../models';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimSelectSchoolClass {
  // ngOnInit(): void {
  //   this.init();
  // }

  contractsService = inject(ContractsService);
  httpErrorService = inject(HttpErrorService);
  fb = inject(FormBuilder);
  router = inject(Router);

  form = this.fb.group({
    district: this.fb.control<C2BDistrictGeneric | null>(null, [Validators.required]),
    school: this.fb.control<C2BSchoolGeneric | null>({ value: null, disabled: true }, [Validators.required]),
    teacher: this.fb.control<C2BTeacherGeneric | null>({ value: null, disabled: true }, [Validators.required]),
  });

  districtChanges = this.form.controls.district.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  schoolChanges = this.form.controls.school.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  teacherChanges = this.form.controls.teacher.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  formChanges = this.form.valueChanges.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  districts$ = this.contractsService.districts$;
  schools$ = this.contractsService.schools$;
  teachers$ = this.contractsService.teachers$;
  schoolTooltip = 'Select District First';
  teacherTooltip = 'Select School First';
  isLoading = false;

  init(): void {
    this.getDistricts();
    this.fillForm();
    this.initValueChanges();
  }

  initValueChanges() {
    this.districtChanges.subscribe(district => {
      if (district) {
        this.getSchools(district.Id);
        this.contractsService.selectedDistirct = district;
      }

      this.form.patchValue({
        school: null,
        teacher: null,
      });
      this.contractsService.selectedSchool = undefined;
      this.contractsService.selectedTeacher = undefined;
      this.contractsService.resetInstrument();
      this.form.controls.school.disable();
      this.schoolTooltip = 'Select District First';
      this.contractsService.setSchools([]);
      this.form.controls.teacher.disable();
      this.teacherTooltip = 'Select School First';
      this.contractsService.setTeachers([]);
    });

    this.schoolChanges.subscribe(school => {
      if (school) {
        this.getTeacherss(school.Id);
        this.contractsService.selectedSchool = school;
      } else {
        this.form.patchValue({ teacher: null });
        this.contractsService.selectedTeacher = undefined;
        this.contractsService.resetInstrument();
        this.form.controls.teacher.disable();
        this.teacherTooltip = 'Select School First';
        this.contractsService.setTeachers([]);
      }
    });

    this.teacherChanges.subscribe(teacher => {
      if (teacher) {
        this.contractsService.selectedTeacher = teacher;
      } else {
        this.contractsService.resetInstrument();
        this.contractsService.selectedTeacher = undefined;
      }
    });

    this.formChanges.subscribe(() => {
      this.contractsService.updateDisabledSteps();
    });
  }

  fillForm() {
    if (this.contractsService.selectedDistirct) {
      this.form.patchValue({ district: this.contractsService.selectedDistirct });
    }

    if (this.contractsService.selectedSchool && this.contractsService.selectedDistirct) {
      this.form.patchValue({ school: this.contractsService.selectedSchool });
      this.form.controls.school.enable();
    }

    if (this.contractsService.selectedTeacher && this.contractsService.selectedSchool) {
      this.form.patchValue({ teacher: this.contractsService.selectedTeacher });
      this.form.controls.teacher.enable();
    }
  }

  getDistricts() {
    if (this.contractsService.getCurrentDistrcits().length) {
      return;
    }

    this.isLoading = true;
    this.contractsService.getDistricts().subscribe({
      next: (districts) => this.onDistrcitSuccess(districts),
      error: (err) => this.onDistrictFailure(err),
    });
  }

  onDistrcitSuccess(districts: C2BDistrictGeneric[]) {
    this.isLoading = false;
    this.contractsService.setDistricts(districts);
  }

  onDistrictFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load districts');
  }

  getSchools(DistrictId: number) {
    this.isLoading = true;
    this.contractsService.setSchools([]);
    this.contractsService.getSchools(DistrictId).subscribe({
      next: (schools) => this.onSchoolSuccess(schools),
      error: (err) => this.onSchoolFailure(err),
    });
  }

  onSchoolSuccess(schools: C2BSchoolGeneric[]) {
    this.isLoading = false;
    this.contractsService.setSchools(schools);
    this.schoolTooltip = '';
    this.form.controls.school.enable();
  }

  onSchoolFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load schools');
  }

  getTeacherss(SchoolId: number) {
    this.isLoading = true;
    this.contractsService.setTeachers([]);
    this.contractsService.getTeachers(SchoolId).subscribe({
      next: (schools) => this.onTeacherSuccess(schools),
      error: (err) => this.onTeacherFailure(err),
    });
  }

  onTeacherSuccess(teachers: C2BTeacherGeneric[]) {
    this.isLoading = false;
    this.contractsService.setTeachers(teachers);
    this.teacherTooltip = '';
    this.form.controls.teacher.enable();
  }

  onTeacherFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load teachers');
  }

  onNextClick() {
    if (this.form.controls.district.value && this.form.controls.school.value && this.form.controls.teacher.value) {
      this.isLoading = true;
      this.contractsService.selectedDistirct = this.form.controls.district.value;
      this.contractsService.selectedSchool = this.form.controls.school.value;
      this.contractsService.selectedTeacher = this.form.controls.teacher.value;
      this.contractsService.updateDisabledSteps();
      this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.SELECT_INSTRUMENT]);
    }
  }
}