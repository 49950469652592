import { Component } from '@angular/core';

@Component({
  selector: 'ngx-aim-admin-edit-item',
  standalone: true,
  imports: [],
  templateUrl: './ngx-aim-admin-edit-item.component.html',
  styleUrls: ['./ngx-aim-admin-edit-item.component.scss']
})
export class NgxAimAdminEditItemComponent {

}
