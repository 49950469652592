import { inject } from '@angular/core';
import { CarouselName, PublicRoutes } from '../enums';
import { AppStateService, UiService } from '../services';

export abstract class NgxAimHomeClass {
  uiService = inject(UiService);
  appState = inject(AppStateService);
  routes = PublicRoutes;
  isMobile$ = this.uiService.isMobile$;
  carouselName = CarouselName.MAIN;
  locations = this.appState.getLocations();
}