import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { ShipEngineCarrier } from '../../../../../../../models';
import { HttpErrorService } from '../../../../../../../services';
import { NgxAimAdminPrimengModule } from '../../../../../ngx-aim-admin.primeng.module';
import { NgxAimAdminShipEngineService } from '../../ngx-aim-admin-ship-engine.service';

@Component({
  selector: 'ngx-aim-admin-edit-carrier-service',
  standalone: true,
  imports: [FormsModule, NgxAimAdminPrimengModule],
  templateUrl: './ngx-aim-admin-edit-carrier-service.component.html',
  styleUrls: ['./ngx-aim-admin-edit-carrier-service.component.scss', '../../../../../admin.scss']
})
export class NgxAimAdminEditCarrierServiceComponent {

  isSaving = false;

  constructor(
    public config: DynamicDialogConfig<ShipEngineCarrier>,
    private AdminShipEngineService: NgxAimAdminShipEngineService,
    private httpErrorService: HttpErrorService,
    private dialogRef: DynamicDialogRef,
  ) {}

  onSave() {
    this.isSaving = true;
    this.AdminShipEngineService.updateServices(this.config.data!.carrier_id, this.config.data!.services).subscribe({
      next: () => this.isSaving = false,
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
