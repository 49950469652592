import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxAimAdminEditCarouselComponent } from './edit-carousel/ngx-aim-admin-edit-carousel.component';
import { OrderListModule } from 'primeng/orderlist';
import { NgxAimAdminPrimengModule } from '../../ngx-aim-admin.primeng.module';
import { CarouselSlide } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { CarouselName, CarouselNames } from '../../../../enums';
import { NgxAimAdminCarouselService } from './ngx-aim-admin-carousel.service';

@Component({
  selector: 'ngx-aim-admin-carousel',
  standalone: true,
  imports: [FormsModule, OrderListModule, NgxAimAdminPrimengModule],
  templateUrl: './ngx-aim-admin-carousel.component.html',
  styleUrls: ['./ngx-aim-admin-carousel.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class NgxAimAdminCarouselComponent {
  carouselNames = CarouselNames;
  selectedCarousel = CarouselName.MAIN;
  slides: CarouselSlide[] = [];
  isLoading = false;

  constructor(
    private entityService: NgxAimAdminCarouselService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private httpErrorService: HttpErrorService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.getCarousel();
  }

  onCarouselChange() {
    this.getCarousel();
  }

  getCarousel() {
    this.isLoading = true;
    this.entityService.findAll(this.selectedCarousel).subscribe({
      next: (slides) => this.getCarouselSuccess(slides),
      error: (err) => this.getCarouselError(err),
    });
  }

  getCarouselSuccess(slides: CarouselSlide[]) {
    this.isLoading = false;
    this.slides = slides;
  }

  getCarouselError(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not retrieve carousel');
  }

  onNewClick() {
    this.openEntity();
  }

  openEntity(entityId?: number) {
    console.log('click', entityId);
    const dialog = this.dialogService.open(NgxAimAdminEditCarouselComponent, {
      data: { entityId: entityId, carouselName: this.selectedCarousel },
      header: 'Carousel Slide',
      width: '900px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.getCarousel();
    });
  }

  onDeleteClick(event: Event, entityId: number): void {
    const entity = this.slides.find(slide => slide.id === entityId);
    this.confirmationService.confirm({
      key: 'onDeleteClick',
      target: event.target!,
      message: `Are you sure that you want to delete ${entity?.name}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelectedData(entityId);
      }
    });
  }

  deleteSelectedData(entityId: number) {
    this.entityService.remove(entityId).subscribe({
      next: (affectedRows) => this.deleteSuccess(affectedRows),
      error: (err) => this.deleteError(err, 'Could not delete records')
    });
  }

  deleteSuccess(affectedRows: number) {
    if (affectedRows === 1) {
      this.messageService.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: 'Slide deleted',
        key: 'app-toast',
        life: 5000
      });
      this.getCarousel();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Record couldn't be deleted`,
        key: 'app-toast',
        life: 5000
      });
      this.getCarousel();
    }
  }

  deleteError(err: any, summary: string) {
    this.httpErrorService.onHttpError(err, summary);
  }

  onReorder(event: any) {
    this.slides.forEach((slide, index) => {
      slide.position = index;
    });

    this.entityService.setOrder(this.slides).subscribe({
      error: (err) => this.httpErrorService.onHttpError(err, 'Could not set order for slides'),
    });
  }
}
