import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { NgxAimVerifiyAddressClass } from './ngx-aim-verify-address.class';

@Component({
  selector: 'ngx-aim-verify-address',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './ngx-aim-verify-address.component.html',
  styleUrls: ['./ngx-aim-verify-address.component.css']
})
export class NgxAimVerifyAddressComponent extends NgxAimVerifiyAddressClass {}
