export enum PublicRoutes {
  SIGN_IN = 'signin',
  CREATE_ACCOUNT = 'create-account',
  CREATE_ACCOUNT_SUCCESSFUL = 'create-account-successful',
  ACTIONS = 'actions',
  VERIFY_EMAIL = 'verify-email',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  PRODUCTS = 'products',
  PRODUCT = 'product',
  CART = 'cart',
  CHECKOUT = 'checkout',
  CHECKOUT_COMPLETE = 'checkout-complete',
  CUSTOMER = 'customer',
  ORDERS = 'orders',
  ABOUT_US = 'about-us',
  RENTALS = 'rentals',
  RENTAL_CHECKOUT = 'rental-checkout',
  ITEM_RENTAL = 'item-rental',
  PAGE_NOT_FOUND = '404',
  PRIVACY_POLICY = 'privacy-policy',
  RETURN_POLICY = 'return-policy',
  SHIPPING_POLICY = 'shipping-policy',
  CONTRACTS = 'contracts',
  SELECT_SCHOOL = 'select-school',
  SELECT_INSTRUMENT = 'select-instrument',
  OPTIONS_ACCESSORIES = 'options-accessories',
  PAYMENT = 'payment',
  CONTRACT_COMPLETE='contract-complete',
  CONTACT_US='contact-us',
  OPEN_INVOICES = 'open-invoices',
  MANAGE_CREDIT_CARD = 'manage-credit-card',
  SETTINGS = 'settings',
  EDIT = 'edit',
  MAKE_PAYMENT = 'make-payment',
  POSTS = 'posts',
}
