import { inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services';
import { PublicRoutes } from '../../enums';

export abstract class NgxAimForgotPasswordClass {
  routes = PublicRoutes;

  fb = inject(NonNullableFormBuilder);
  userService = inject(UserService);

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  isLoading = false;
  isDialogVisible = false;
  error = '';

  onSubmit() {
    const email = this.form.controls.email.value;
    if (!email) {
      return;
    }
    this.isLoading = true;
    this.userService.sendPasswordResetEmail(email).subscribe({
      next: () => this.onSuccess(),
      error: (err) => this.onError(err),
    });
  }

  onSuccess() {
    this.isLoading = false;
    this.isDialogVisible = true;
  }

  onError(err: any) {
    this.isLoading = false;
    this.error = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }

  get fc() { return this.form.controls; }
}