<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="name" formControlName="name" />
        <label htmlFor="name">Name</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address1" formControlName="address1" />
        <label htmlFor="address1">Address 1</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="address2" formControlName="address2" />
        <label htmlFor="address2">Address 2</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="city" formControlName="city" />
        <label htmlFor="city">City</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="(states$ | async)!"
                    [lazy]="true"
                    [filter]="true"
                    [autoDisplayFirst]="false"
                    optionLabel="name"
                    optionValue="abbreviation"
                    formControlName="state"
                    filterBy="name">
          <ng-template let-state pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ state.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <label htmlFor="state">State</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="zip" formControlName="zip" />
        <label htmlFor="zip">Zip</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="phone" formControlName="phone" />
        <label htmlFor="phone">Phone</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="fflNumber" formControlName="fflNumber" />
        <label htmlFor="fflNumber">FFL Number</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="willAcceptAllTransfers" placeholder="Accepts Transfers"  inputId="willAcceptAllTransfers"></p-dropdown>
        <label for="willAcceptAllTransfers">Accepts Transfers</label>
    </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
  <p-divider align="left">
    <i class="pi pi-info-circle mr-2"></i>
    <b>Metadata</b>
  </p-divider>
</div>
<div class="col-12">
  <form [formGroup]="metaForm" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="createDate" formControlName="createDate" />
        <label htmlFor="createDate">Created</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="updateDate" formControlName="updateDate" />
        <label htmlFor="updateDate">Updated</label>
      </span>
    </div>
  </form>
</div>
@if (isLoading) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}