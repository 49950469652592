import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { NgxAimSettingsClass } from './ngx-aim-settings.class';

@Component({
  selector: 'ngx-aim-settings',
  standalone: true,
  imports: [ButtonModule, RouterLink],
  templateUrl: './ngx-aim-settings.component.html',
  styleUrl: './ngx-aim-settings.component.css'
})
export class NgxAimSettingsComponent extends NgxAimSettingsClass {}
