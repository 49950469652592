import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PaymentLog } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { NgxAimAdminOrderService } from '../../ngx-aim-admin-order.service';
import { DatePipe, JsonPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-order-payment-log',
  standalone: true,
  imports: [DatePipe, JsonPipe],
  templateUrl: './ngx-aim-admin-order-payment-log.component.html',
  styleUrls: ['./ngx-aim-admin-order-payment-log.component.scss']
})
export class NgxAimAdminOrderPaymentLogComponent {
  log: PaymentLog[] = [];

  constructor(
    private orderService: NgxAimAdminOrderService,
    private config: DynamicDialogConfig,
    private httpErrorService: HttpErrorService
  ) {
    this.orderService.getPaymentLog(this.config.data).subscribe({
      next: (log) => this.log = log,
      error: (err) => this.httpErrorService.onHttpError(err, 'Could retrieve payment log'),
    });
  }
}
