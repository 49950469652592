import { inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CheckoutCompleteNavExtras } from '../../models';

export abstract class NgxAimContractCompleteClass {
  orderId = '';
  location = inject(Location);
  router = inject(Router);

  constructor() {
    const state = this.location.getState() as CheckoutCompleteNavExtras;
    if (state?.orderId) {
      this.orderId = state.orderId;
    } else {
      this.router.navigateByUrl('/');
    }
  }
}