import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { AppLazyLoadEvent, AppPost, FindAllResult, SaveAppPostDto } from '../../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminAppPostsService extends GenericSearchService<AppPost> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/app-post`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService,
  ) {
    super(_httpErrorService, 'Users');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<AppPost>> {
    return this.http.post<FindAllResult<AppPost>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<AppPost> {
    return this.http.get<AppPost>(`${this.baseUrl}/post/${id}`);
  }

  save(dto: SaveAppPostDto): Observable<HttpEvent<AppPost>> {
    return this.http.post<AppPost>(this.baseUrl, dto, {
      reportProgress: true,
      observe: 'events',
    });
  }

  delete(ids: number[]): Observable<number> {
    return this.http.delete<number>(this.baseUrl, {
      params: new HttpParams()
        .set('ids', ids.join(','))
    });
  }
}
