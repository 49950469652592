import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxAimPostsService } from '../ngx-aim-posts.service';
import { AppPost } from '../../../models';
import { HttpErrorService } from '../../../services';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxAimPostComponent } from '../post/ngx-aim-post.component';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxPostClass {
  route = inject(ActivatedRoute);
  postsService = inject(NgxAimPostsService);
  httpErrorService = inject(HttpErrorService);
  dialogService = inject(DialogService);

  posts: AppPost[] = [];
  category = '';

  // ngOnInit() {
  //   const category = this.route.snapshot.paramMap.get('category');
  //   if (category) {
  //     this.getPosts(category);
  //   }
  // }

  getPosts(category: string) {
    this.category = category;
    this.postsService.findAllByCategory(category).subscribe({
      next: (appPosts) => this.onGetPostsSuccess(appPosts),
      error: (err) => this.onGetFailure(err)
    });
  }

  onGetPostsSuccess(appPosts: AppPost[]) {
    this.posts = appPosts;
  }

  onGetFailure(err: any) {
    this.httpErrorService.onHttpError(err, 'Could not find that post');
  }

  onPostClick(slug: string) {
    const post = this.posts.find((p) => p.slug === slug);
    const dialog = this.dialogService.open(NgxAimPostComponent, {
      data: { post: post },
      contentStyle: { 'max-height': '90%', 'overflow': 'auto' },
      showHeader: false,
      dismissableMask: true,
    });
  }
}