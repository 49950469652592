<div class="col-12">
  <h2>{{config.data?.friendly_name}}</h2>
  @for (service of config.data?.services; track service; let i = $index;) {
    <div class="mb-3">
      <p-checkbox 
                  [(ngModel)]="service.active"
                  [binary]="true"
                  [inputId]="service.service_code">
      </p-checkbox>
      <label class="ml-2" [for]="service.service_code">{{service.name}}</label>
    </div>
  }
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
@if (isSaving) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
