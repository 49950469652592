<p-blockUI [target]="serialDeliveryOptions" [blocked]="(loading$ | async)!" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<ngx-aim-p-blockable-div #serialDeliveryOptions>
  <div class="flex flex-column gap-3" style="min-height: 90px;">
    @for (option of options$ | async; track option.rate_id) {
      <div class="field-checkbox">
        <p-radioButton [inputId]="option.rate_id" name="option" [value]="option" [(ngModel)]="selectedOption"></p-radioButton>
        <label [for]="option.rate_id" class="ml-2">{{option.service_type}} &mdash; {{option.total_amount | currency}}</label>
      </div>
    }
  </div>
  <p-button (onClick)="onSelectOptionCLick()" [disabled]="!this.selectedOption">Use this option</p-button>
</ngx-aim-p-blockable-div>
