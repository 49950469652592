<div id="teachers-container">
  <h1>Music Lessons at Aim Base</h1>
  <p>We are pleased to offer violin, viola and cello lessons, right here in our shop. Lessons can be scheduled for 30, 45, or 60 minutes. Students of all ages and levels of experience are welcome. For more information or to schedule lessons, please contact the teacher directly via the contact method listed under their profile.</p>
  <h2>Teaching Faculty at Aim Base</h2>

  <div class="teacher-posts">
    @for (post of posts; track post.id) {
      <div class="teacher-post">
        <img class="teacher-post-image" [src]="'files/' + post.image">
        <h3>{{post.title}}</h3>
        <div class="teacher-post-body" [innerHTML]="post.body"></div>
        <hr>
        <div class="teacher-learn-more pointer" (click)="onPostClick(post.slug)">
          <span>LEARN MORE</span>
          <div>→</div>
        </div>
      </div>
    }
  </div>
</div>