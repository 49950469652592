import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { PublicRoutes } from '../../enums';
import { inject } from '@angular/core';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimCreateAccountSuccessfulClass {
  // ngOnInit() {
  //   this.init();
  // }

  location = inject(LocationStrategy);
  router = inject(Router);

  routes = PublicRoutes;
  email = '';

  init() {
    const state = this.location.getState() as { email: string, navigationId: number };
    if (state?.email) {
      this.email = state.email;
    } else {
      this.router.navigateByUrl('/');
    }
  }
}