import { inject } from '@angular/core';
import { AppStateService, HttpErrorService, ShortTermRentalCheckoutService } from '../../../services';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CheckoutTotals, ProcessOrderResponse } from '../../../models';
import { PaymentStatus, PaymentType } from '../../../enums';
import { MessageService } from 'primeng/api';
import { NavigationExtras, Router } from '@angular/router';

/** when extending this class be sure to implement ngOnInit,
 * @Input({ required: true })
 * override completeUrl: string = '';
 */
export abstract class NgxAimOverviewRentalsClass {
  // ngOnInit() {
  //   this.init();
  // }

  checkoutService = inject(ShortTermRentalCheckoutService);
  httpErrorService = inject(HttpErrorService);
  appState = inject(AppStateService);
  messageService = inject(MessageService);
  router = inject(Router);

  completeUrl: string = '';

  totals$ = this.checkoutService.totals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals: CheckoutTotals = {
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  }
  rentalData = this.checkoutService.getRentalData();
  strData$ = this.checkoutService.strData$;
  isProcessing = false;
  showError = false;

  init() {
    this.totals$.subscribe(totals => {
      this.totals = totals;
    });
  }

  onCompleteClick() {
    if (this.appState.paymentType === PaymentType.NONE && this.totals.total !== 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Failed',
        detail: 'Cannot not check out at this time. No payment processor',
        key: 'app-toast'
      });
      return;
    }

    this.isProcessing = true;
    this.checkoutService.checkout().subscribe({
      next: (res) => this.handleCheckoutResponse(res),
      error: (err) => this.onError(err),
    });
  }

  handleCheckoutResponse(res: ProcessOrderResponse) {
    this.isProcessing = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.checkoutService.reset();

      const navigationExtras: NavigationExtras = {
        state: {
          orderId: res.orderId,
        },
      }
      this.router.navigate(['/', this.completeUrl], navigationExtras);
    } else {
      this.showError = true;
    }
  }

  onError(err: any) {
    this.isProcessing = false;
    this.httpErrorService.onHttpError(err, `We couldn't process your order`);
  }
}
