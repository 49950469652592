import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractsService, HttpErrorService } from '../../services';
import { C2BGradeGeneric, C2BInstrumentGeneric } from '../../models';
import { PublicRoutes } from '../../enums';
import {
  AcousticGuitar,
  AltoSax,
  BandNames,
  Bass,
  Cello,
  Clarinet,
  ElectricGuitar,
  Flute,
  FrenchHorn,
  OrchestraNames,
  TenorSax,
  Trombone,
  Trumpet,
  Tuba,
  Viola,
  Violin
} from '../../constants';

/** when extending this class be sure to implement ngOnInit,
 * @ViewChild('gradeDropDown')
 * override gradeDropDown: Dropdown | undefined;
 * 
 * @ViewChild('sizeDropDown')
 * override sizeDropDown: Dropdown | undefined;
 */
export abstract class NgxAimSelectInstrumentClass {
  // ngOnInit(): void {
  //   this.init();
  // }

  router = inject(Router);
  contractsService = inject(ContractsService);
  httpErrorService = inject(HttpErrorService);
  fb = inject(FormBuilder);

  gradeDropDown: Dropdown | undefined;
  sizeDropDown: Dropdown | undefined;

  form = this.fb.group({
    instrumentType: this.fb.control<C2BInstrumentGeneric | null>(null, [Validators.required]),
    instrument: this.fb.control<C2BInstrumentGeneric | null>({ value: null, disabled: true }, [Validators.required]),
    grade: this.fb.control<C2BGradeGeneric | null>({ value: null, disabled: true }, [Validators.required]),
  });

  instrumentTypes: C2BInstrumentGeneric[] = [];
  instrumentSizes: C2BInstrumentGeneric[] = [];
  grades$ = this.contractsService.grades$;
  sizeTooltip = 'Select Instrument Frist';
  gradeTooltip = 'Select Instrument Frist';
  isLoading = false;
  isMobile = false;

  constructor() {
    const teacher = this.contractsService.selectedTeacher;
    if (OrchestraNames.some((name) => teacher?.TeacherName.toLowerCase().includes(name))) {
      this.instrumentTypes = [
        Violin,
        Viola,
        Cello,
        Bass,
      ];
    }

    if (BandNames.some((name) => teacher?.TeacherName.toLowerCase().includes(name))) {
      this.instrumentTypes = [
        Flute,
        Clarinet,
        Trombone,
        Trumpet,
        AltoSax,
        TenorSax,
        FrenchHorn,
        Tuba,
      ];
    }
  }

  init(): void {
    this.getInstruments();
    this.fillForm();
  }

  getInstruments() {
    if (!this.contractsService.selectedTeacher) {
      this.router.navigate(['/', PublicRoutes.CONTRACTS]);
      return;
    }

    this.isLoading = true;
    this.contractsService.getInstruements(this.contractsService.selectedTeacher.Id).subscribe({
      next: (instruments) => this.onSuccess(instruments),
      error: (err) => this.onFailure(err),
    });
  }

  fillForm() {
    const instrumentType = this.contractsService.getCurrentInstrumentType();
    if (instrumentType) {
      this.form.patchValue({ instrumentType: instrumentType });
      this.form.controls.instrument.enable();
      this.instrumentSizes = this.contractsService
        .getCurrentInstruments()
        .filter((ins) => ins.InstrumentName.toLowerCase().includes(instrumentType.InstrumentName.toLowerCase()));
    }

    if (this.contractsService.selectedInstrument) {
      this.form.patchValue({ instrument: this.contractsService.selectedInstrument });
      this.sizeTooltip = '';
    }

    if (this.contractsService.selectedGrade && this.contractsService.selectedInstrument) {
      this.form.patchValue({ grade: this.contractsService.selectedGrade });
      this.form.controls.grade.enable();
      this.gradeTooltip = '';
    }
  }

  onSuccess(instruemnts: C2BInstrumentGeneric[]) {
    instruemnts.forEach(inst => {
      if (!inst.Image) {
        inst.Image = '/images/instruments/missing-image.webp';
      }
    });
    this.isLoading = false;
    this.contractsService.setInstruments(instruemnts);
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load instruments');
  }

  onInstrumentTypeClick(instrument: C2BInstrumentGeneric, el: HTMLElement) {
    el?.scrollIntoView({ behavior: 'smooth' });
    this.sizeTooltip = '';
    this.form.controls.instrumentType.setValue(instrument);
    this.contractsService.setInstrumentType(instrument);
    this.instrumentSizes = this.contractsService
      .getCurrentInstruments()
      .filter((ins) => ins.InstrumentName.toLowerCase().includes(instrument.InstrumentName.toLowerCase()));

    this.form.controls.instrument.enable();
    if (this.instrumentSizes.length >= 1) {
      setTimeout(() => {
        this.sizeDropDown?.show();
      }, 10);
      this.gradeTooltip = 'Select Size First';
    } else {
      this.form.controls.grade.enable();
      this.gradeDropDown?.show();
      this.form.controls.instrument.setValue(this.instrumentSizes[0]);
      this.onInstrumentChange();
    }
  }

  onInstrumentChange() {
    const instrument = this.form.controls.instrument.value;
    if (!instrument) return;
    this.contractsService.setGrades(instrument.GradeList);
    this.contractsService.selectedInstrument = instrument;
    this.contractsService.selectedAccessories = [];
    this.contractsService.selectedMaintenance = [];
    this.contractsService.selectedDelivery = undefined;
    this.gradeTooltip = '';
    this.form.controls.grade.enable();
    this.gradeDropDown?.show();
  }

  onGradeChange($event: DropdownChangeEvent) {
    if (this.form.controls.grade.value) {
      this.contractsService.selectedGrade = this.form.controls.grade.value;
      this.contractsService.updateDisabledSteps();
    }
  }

  onNextClick() {
    this.isLoading = true;
    this.contractsService.updateDisabledSteps();
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.OPTIONS_ACCESSORIES]);
  }

  onBackClick() {
    this.router.navigate(['/', PublicRoutes.CONTRACTS, PublicRoutes.SELECT_SCHOOL]);
  }
}