import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasImage',
  standalone: true,
  pure: true,
})
export class HasImagePipe implements PipeTransform {
  transform(imageSrc: string): string {
    if (imageSrc) {
      return `/files/${imageSrc}`;
    } else {
      return 'images/others/missing-image.png';
    }
  }
}
