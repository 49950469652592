import { inject } from '@angular/core';
import { HttpErrorService, ShortTermRentalService } from '../../services';
import { Category } from '../../models';

/** when extending this class be sure to implement ngOnInit */
export class NgxAimRentalsClass {
  // ngOnInit() {
  //   this.init();
  // }

  rentalService = inject(ShortTermRentalService);
  httpErrorService = inject(HttpErrorService);

  categories: Category[] = [];
  isLoading = true;

  init() {
    this.rentalService.getCategories().subscribe({
      next: (categories) => this.onSuccess(categories),
      error: (err) => this.onFailure(err),
    });
  }

  onSuccess(categories: Category[]) {
    this.isLoading = false;
    this.categories = categories;
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not load categories. Please try again');
  }
}
