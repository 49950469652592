<div class="flex flex-row flex-wrap justify-content-between">
  @if (order?.accessoryShipRateId) {
    <div class="flex">
      <div class="flex flex-column">
        <h3>{{shippingTitle}}</h3>
        <span><strong>Service Type:</strong> {{order?.accessoryDelivery}}</span>
        <span><strong>Tracking Number:</strong> {{order?.accessoryTrackingNumber}}</span>
        @if (!order?.accessoryTrackingNumber) {
          <p-button (onClick)="onCreateLabelClick(itemTypes.ACCESSORY)"
                    [loading]="isSaving"
                    [disabled]="order?.accessoryShipRateId?.startsWith('app-')"
                    label="CREATE LABEL"
                    class="ml-2 mt-2">
          </p-button>
        }
        @else {
          <p-button (onClick)="onViewLabelClick(order?.accessoryDeliveryLabelPdfUrl)" label="VIEW LABEL" class="ml-2 mt-2"></p-button>
        }
      </div>
    </div>
  }
  @if (order?.serializedShipRateId) {
    <div class="flex">
      <div class="flex flex-column">
        <h3>FFL</h3>
        <span><strong>Service Type:</strong> {{order?.serializedDelivery}}</span>
        <span><strong>Tracking Number:</strong> {{order?.serializedTrackingNumber}}</span>
        @if (!order?.serializedTrackingNumber) {
          <p-button (onClick)="onCreateLabelClick(itemTypes.SERIALIZED)"
                    [loading]="isSaving"
                    [disabled]="order?.serializedShipRateId?.startsWith('app-')"
                    label="CREATE LABEL"
                    class="ml-2 mt-2">
          </p-button>
        }
        @else {
          <p-button (onClick)="onViewLabelClick(order?.serializedLabelPdfUrl)" label="VIEW LABEL" class="ml-2 mt-2"></p-button>
        }
      </div>
    </div>
  }
</div>
