import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../ngx-aim.service';
import { Observable } from 'rxjs';
import { TsysSettingsDto } from '../../../../models';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminTsysService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/payment/tsys`;

  constructor(
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {}

  getPaymentSettings(): Observable<TsysSettingsDto> {
    return this.http.get<TsysSettingsDto>(this.baseUrl);
  }

  savePaymentSettings(settings: TsysSettingsDto) {
    return this.http.post(this.baseUrl, settings);
  }

  generateTransactionKey(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/generateTransactionKey`, {});
  }
}
