import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { SelectedAttribute, WebCategoryJsonAttribute } from '../../../../models';
import { NgxAimAttributeFilterClass } from './ngx-aim-attribute-filter.class';

@Component({
  selector: 'ngx-aim-attribute-filter',
  standalone: true,
  imports: [DropdownModule, FormsModule],
  templateUrl: './ngx-aim-attribute-filter.component.html',
  styleUrls: ['./ngx-aim-attribute-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimAttributeFilterComponent extends NgxAimAttributeFilterClass {
  @Input()
  override attribute: WebCategoryJsonAttribute | undefined;

  @Input()
  override set selectedAttributes(attributes: SelectedAttribute[]) {
    for (const att of attributes) {
      if (att.Description === this.attribute?.Description) {
        this.selected = att.selectedValue;
        return;
      }
    }
    this.selected = null;
  }
}
