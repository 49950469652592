import { EventEmitter, inject } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthenticationService } from '../../services';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement
 * @Output()
 * override closeOverlay = new EventEmitter<void>();
 */
export abstract class NgxAimHeaderAccountClass {
  authService = inject(AuthenticationService);
  router = inject(Router);

  currentUser$ = this.authService.currentUser$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  closeOverlay!: EventEmitter<void>;
  routes = PublicRoutes;
  usersName = '';
  buttonStyleClass = 'text-xs';

  constructor() {
    this.currentUser$.subscribe((currentUser) => {
      if (currentUser) {
        this.usersName = `${currentUser.firstName} ${currentUser.lastName}`;
      } else {
        this.usersName = '';
      }
    });
  }

  onLogoutClick() {
    this.closeOverlay.emit();
    this.authService.logout();
  }

  onNavigate(url: string) {
    this.router.navigateByUrl(url);
    this.closeOverlay.emit();
  }
}
