import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxAimTimePickerComponent } from '../../../time-picker/ngx-aim-time-picker.component';
import { HasImagePipe } from '../../../../pipes/has-image/has-image.pipe';
import { NgxAimRentalCheckAvailabilityClass } from './ngx-aim-rental-check-availability.class';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-rental-check-availability',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CalendarModule,
    HasImagePipe,
    DropdownModule,
    ProgressSpinnerModule,
    NgxAimTimePickerComponent,
    CurrencyPipe,
  ],
  templateUrl: './ngx-aim-rental-check-availability.component.html',
  styleUrls: ['./ngx-aim-rental-check-availability.component.css']
})
export class NgxAimRentalCheckAvailabilityComponent extends NgxAimRentalCheckAvailabilityClass implements OnInit {
  ngOnInit() {
    this.init();
  }
}
