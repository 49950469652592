import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { TsysSettingsDto } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { NgxAimAdminTsysService } from './ngx-aim-admin-tsys.service';
import { TsysSettingNames } from '../../../../enums';

@Component({
  selector: 'ngx-aim-admin-tsys',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, ButtonModule, ProgressSpinnerModule],
  templateUrl: './ngx-aim-admin-tsys.component.html',
  styleUrls: ['./ngx-aim-admin-tsys.component.css', '../../admin.scss'],
})
export class NgxAimAdminTsysComponent {
  form = this.fb.group({
    [TsysSettingNames.USER_ID]: this.fb.control<string>(''),
    [TsysSettingNames.PASSWORD]: this.fb.control<string>(''),
    [TsysSettingNames.MID]: this.fb.control<string>(''),
  });

  isLoading = false;
  isSaving = false;
  constructor(
    private paymentService: NgxAimAdminTsysService,
    private httpErrorService: HttpErrorService,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {
    this.paymentService.getPaymentSettings().subscribe({
      next: (settings) => this.form.patchValue(settings),
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  onSaveClick() {
    this.isSaving = true;
    const settings: TsysSettingsDto = {
      [TsysSettingNames.USER_ID]: this.form.controls[TsysSettingNames.USER_ID].value || '',
      [TsysSettingNames.PASSWORD]: this.form.controls[TsysSettingNames.PASSWORD].value || '',
      [TsysSettingNames.MID]: this.form.controls[TsysSettingNames.MID].value || '',
    }

    this.paymentService.savePaymentSettings(settings).subscribe({
      next: () => this.onSaveSuccess(),
      error: (err) => this.onSaveError(err),
    });
  }

  onSaveSuccess() {
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Tsys settings saved',
      key: 'app-toast'
    });
  }

  onSaveError(err: any) {
    this.isSaving = false;
    this.httpErrorService.onHttpError(err, 'Could not save tsys settings');
  }

  onGenerateTransactionKeyClick() {
    this.isSaving = true;
    this.paymentService.generateTransactionKey().subscribe({
      next: () => this.onSaveSuccess(),
      error: (err) => this.onSaveError(err)
    });
  }

  onClearClick() {
    this.form.patchValue({
      [TsysSettingNames.USER_ID]: '',
      [TsysSettingNames.PASSWORD]: '',
      [TsysSettingNames.MID]: '',
    });
  }
}
