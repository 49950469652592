<div class="grid">
  <div class="col-12">
    <p-dataView #dv [value]="cart.items" [lazy]="true">
      <ng-template let-items pTemplate="listItem">
        @for (item of items; track item.Pk; let first = $first) {
          <div class="col-12">
            <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
              <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="item.thumbSrc | hasImage" [alt]="item.Model" />
              <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                <div class="text-2xl font-bold text-900">{{ item.Model }}</div>
                <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                  <span class="text-2xl font-semibold">
                    {{(item.ActiveePrice * item.cartQty) | currency}}
                  </span>
                  <span>
                    Qty: {{item.cartQty}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </ng-template>
    </p-dataView>
  </div>
</div>
<div class="text-right text-2xl font-semibold pr-3">Items: {{totals.items | currency}}</div>
<div class="text-right text-2xl font-semibold pr-3">Shipping: {{totals.shipping | currency}}</div>
<div class="text-right text-2xl font-semibold pr-3">Tax: {{totals.tax | currency}}</div>
<p-divider></p-divider>
<div class="text-right text-3xl font-semibold pr-3 mb-2">Total: {{totals.total | currency}}</div>
<div class="text-right text-3xl font-semibold pr-3">
  <p-button (onClick)="onCompleteClick()" [loading]="isProcessing" [disabled]="isProcessing" label="CHECKOUT"></p-button>
</div>
<p-dialog header="Error" [(visible)]="showError">
  <p>
    Your payment was declined.
  </p>
</p-dialog>
