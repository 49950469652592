<h1 class="mt-4">Tsys Settings</h1>
<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form mt-2 form-width">
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="user_id" formControlName="user_id" />
        <label htmlFor="user_id">User ID</label>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="password" formControlName="password" />
        <label htmlFor="password">Password</label>
      </span>
    </div>
    <div class="field col-12">
      <span class="p-float-label">
        <input pInputText id="mid" formControlName="mid" />
        <label htmlFor="mid">MID</label>
      </span>
    </div>
  </form>
  <div class="flex justify-content-end form-width">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSaveClick()">
    </p-button>
    <p-button label="Clear"
              icon="pi pi-trash"
              styleClass="p-button-text"
              (onClick)="onClearClick()">
    </p-button>
  </div>
  <div class="flex justify-content-end form-width">
    <p-button label="Generate Transaction Key"
              icon="pi pi-key"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onGenerateTransactionKeyClick()">
    </p-button>
  </div>
</div>
@if (isLoading) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
