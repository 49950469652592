import { C2BInstrumentGeneric } from '../models';

/** This enum will be used to filter out different sizes of instruments
 * @readonly
 * @enum {string}
 */
export enum Instrument {
  VIOLIN = 'Violin',
  VIOLA = 'Viola',
  CELLO = 'Cello',
  BASS = 'Bass',
  FLUTE = 'Flute',
  CLARINET = 'Clarinet',
  TROMBONE = 'Trombone',
  TRUMPET = 'Trumpet',
  ALTO_SAX = 'Alto Sax',
  TENOR_SAX = 'Tenor Sax',
  FRENCH_HORN = 'French Horn',
  TUBA = 'Tuba',
  ELECTRIC_GUITAR = 'Electric Guitar',
  ACOUSTIC_GUITAR = 'Acoustic Guitar',
};

export const EMPTY_INSTRUMENT: C2BInstrumentGeneric = {
  InstrumentName: '',
  Sku: '',
  Topic: '',
  Image: '',
  Level: 0,
  RentPrice: 0,
  CashPrice: 0,
  Token: '',
  InstrumentId: 0,
  InstrumentTeacherId: 0,
  GradeList: [],
  HasGrades: true,
  DisplayOrder: 0,
  AccessoryList: [],
  DeliveryList: [],
  MaintenanceList: [],
}

export const Violin: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.VIOLIN,
  Image: '/images/instruments/violin.webp',
}

export const Viola: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.VIOLA,
  Image: '/images/instruments/viola.webp',
}

export const Cello: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.CELLO,
  Image: '/images/instruments/cello.webp',
}

export const Bass: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.BASS,
  Image: '/images/instruments/bass.webp',
}

export const Flute: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.FLUTE,
  Image: '/images/instruments/flute.webp',
}

export const Clarinet: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.CLARINET,
  Image: '/images/instruments/clarinet.webp',
}

export const Trombone: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.TROMBONE,
  Image: '/images/instruments/trombone.webp',
}

export const Trumpet: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.TRUMPET,
  Image: '/images/instruments/trumpet.webp',
}

export const AltoSax: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.ALTO_SAX,
  Image: '/images/instruments/alto-sax.webp',
}

export const TenorSax: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.TENOR_SAX,
  Image: '/images/instruments/tenor-sax.webp',
}

export const FrenchHorn: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.FRENCH_HORN,
  Image: '/images/instruments/french-horn.webp',
}

export const Tuba: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.TUBA,
  Image: '/images/instruments/tuba.webp',
}

export const ElectricGuitar: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.ELECTRIC_GUITAR,
  Image: '/images/instruments/electric-guitar.webp',
}

export const AcousticGuitar: C2BInstrumentGeneric = {
  ...EMPTY_INSTRUMENT,
  InstrumentName: Instrument.ACOUSTIC_GUITAR,
  Image: '/images/instruments/acoustic-guitar.webp',
}

export const OrchestraNames = [
  'orchestra',
  'strings',
];

export const BandNames = [
  'band',
];