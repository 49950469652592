import { Injectable } from '@angular/core';
import { AppLazyLoadEvent, ContractFormInput, FindAllResult } from '../../../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxAimService } from '../../../ngx-aim.service';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminContractsService extends GenericSearchService<ContractFormInput> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/contracts`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService,
  ) {
    super(_httpErrorService, 'ContractFormInputs');
  }

  save(formInput: Partial<ContractFormInput>): Observable<ContractFormInput> {
    return this.http.post<ContractFormInput>(`${this.baseUrl}/form-input`, formInput);
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<ContractFormInput>> {
    return this.http.post<FindAllResult<ContractFormInput>>(`${this.baseUrl}/form-input/all`, dto);
  }

  findOne(id: number): Observable<ContractFormInput> {
    return this.http.get<ContractFormInput>(`${this.baseUrl}/form-input/${id}`);
  }

  delete(ids: number[]): Observable<number> {
    return this.http.delete<number>(this.baseUrl, {
      params: new HttpParams()
        .set('ids', ids.join(','))
    });
  }
}
