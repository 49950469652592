import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckoutType, AddressType } from '../../../enums';
import { GenericCheckoutService } from '../../../services';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { NgxAimPBlockableDivComponent } from '../../p-blockable-div/ngx-aim-p-blockable-div.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NgxAimAddressClass } from './ngx-aim-address.class';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-address',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    OverlayPanelModule,
    InputSwitchModule,
    DropdownModule,
    NgxAimPBlockableDivComponent,
    BlockUIModule,
    ProgressSpinnerModule,
    ToastModule,
    ConfirmDialogModule,
    AsyncPipe,
  ],
  templateUrl: './ngx-aim-address.component.html',
  styleUrls: ['./ngx-aim-address.component.css'],
  providers: [DialogService, ConfirmationService],
})
export class NgxAimAddressComponent extends NgxAimAddressClass implements OnInit {
  @Input()
  override type: AddressType = AddressType.SHIPPING;

  @Input({ required: true })
  override checkoutService!: GenericCheckoutService;

  @Input({ required: true })
  override checkoutType: CheckoutType = CheckoutType.PURCHASE;

  ngOnInit() {
    this.init();
  }
}
