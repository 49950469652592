import { inject } from '@angular/core';
import { ItemFilterService } from '../../../services';

export abstract class NgxAimProductFilterClass {
  filterService = inject(ItemFilterService);

  subCategories = this.filterService.subCategories$;
  attributes = this.filterService.attributes$;
  selectedAttributes = this.filterService.selectedAttributes$;
  minMax = this.filterService.minMax$;
}
