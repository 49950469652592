import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DataViewModule } from 'primeng/dataview';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';
import { ChangeDetectionStrategy } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { NgxAimProductFilterComponent } from './product-filter/ngx-aim-product-filter.component';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxAimProductPriceComponent } from './product-price/ngx-aim-product-price.component';
import { GetProductLinkPipe, HasImagePipe, OutOfStockPipe, ProductActionButtonLabelPipe } from '../../pipes';
import { NgxProductsComponent } from './ngx-aim-products.class';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-products',
  standalone: true,
  imports: [
    DataViewModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    HasImagePipe,
    DropdownModule,
    SidebarModule,
    NgxAimProductFilterComponent,
    RouterLink,
    ChipModule,
    ProgressSpinnerModule,
    NgxAimProductPriceComponent,
    ProductActionButtonLabelPipe,
    GetProductLinkPipe,
    OutOfStockPipe,
    AsyncPipe,
    NgClass,
  ],
  templateUrl: './ngx-aim-products.component.html',
  styleUrls: ['./ngx-aim-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class NgxAimProductsComponent extends NgxProductsComponent implements OnInit {
  ngOnInit() {
    this.init();
  }
}
