import { inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AppStateService, CartService, CheckoutService, HttpErrorService } from '../../../services';
import { SelectItem } from 'primeng/api';
import { Ffl, ItemInventoryType } from '../../../models';
import { NgxAimFflSelectorService } from './ngx-aim-ffl-selector.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { CheckoutTabNames, StoreType } from '../../../enums';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxAimAddFflComponent } from './add-ffl/ngx-aim-add-ffl.component';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimFflSelectorClass {
  // ngOnInit() {
  //   this.init();
  // }

  fb = inject(FormBuilder);
  checkoutService = inject(CheckoutService);
  fflService = inject(NgxAimFflSelectorService);
  httpErrorService = inject(HttpErrorService);
  dialogService = inject(DialogService);
  appState = inject(AppStateService);
  cartService = inject(CartService);

  distanceOptions: SelectItem<string>[] = [
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '100', value: '100' },
    { label: '500', value: '500' },
  ];

  form = this.fb.group({
    zipcode: this.fb.control<string>(''),
    distance: this.fb.control<SelectItem<string>>(this.distanceOptions[1]),
    state: this.fb.control<string>(''),
  });

  userAddress$ = this.checkoutService.userBillingAddress$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  ffls: Ffl[] = [];
  selectedFfl: Ffl | undefined;
  isLoading = false;
  isSaving = false;
  showInStorePickupBtn = false;

  init() {
    this.userAddress$.subscribe(address => {
      this.form.patchValue({
        zipcode: address.Zip && address.Zip.slice(0, 5),
        state: address.State,
      });

      if (this.appState.inStorePickup) {
        if (this.appState.storeType === StoreType.FIREARMS) {
          this.showInStorePickupBtn = address.State === this.appState.getDefaultLocation().state;
        } else {
          this.showInStorePickupBtn = this.appState.inStorePickup;
        }
      }
    });
  }

  findAll() {
    this.isLoading = true;
    this.fflService.findAll(
      this.form.controls.zipcode.value || '',
      this.form.controls.distance.value?.value || '',
      this.form.controls.state.value || '',
    ).subscribe({
      next: (ffls) => { this.ffls = ffls; this.isLoading = false; },
      error: (err) => { this.httpErrorService.onHttpError(err, 'Could not load FFLs. Please try again'); this.isLoading = false; }
    });
  }

  onInStorePickupClick() {
    this.goForward();
  }

  onUseThisFflClick() {
    if (!this.selectedFfl) {
      return;
    }

    this.checkoutService.selectedFfl = this.selectedFfl;
    this.goForward();
  }

  goForward() {
    const isFirearmStoreAndHasAccessoryItem = this.appState.storeType === StoreType.FIREARMS && this.cartService.hasCartGotAccessoryItem();
    this.checkoutService.getDeliveryOptions && this.checkoutService.getDeliveryOptions(ItemInventoryType.SERIALIZED);
    if (isFirearmStoreAndHasAccessoryItem) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.DELIVERY_OPTIONS, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.DELIVERY_OPTIONS);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.DELIVERY_OPTIONS_SERIALIZED);
    }
  }

  onAddClick() {
    const dialog = this.dialogService.open(NgxAimAddFflComponent, {
      header: 'Add FFL',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe((ffl: Ffl) => {
      if (ffl) {
        this.selectedFfl = ffl;
        this.onUseThisFflClick();
      }
    });
  }
}
