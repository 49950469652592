<div class="header">
  <button pButton pRipple label="CLOSE" (click)="onCloseClick()"></button>
</div>
@if(post) {
  <div class="post-container">
    <div><img [src]="'files/' + post.image"></div>
    <div>
      <h3>
        {{post.title}}
      </h3>
      <div class="post-body" [innerHTML]="post.body"></div>
    </div>
  </div>
}

