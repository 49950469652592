import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { DOCUMENT, NgClass, NgStyle } from '@angular/common';
import { GenericCheckoutService, HttpErrorService, PaymentService } from '../../../services';
import { NgxAimGlobalpayService } from './ngx-aim-globalpay.service';
import { GPDataValid, GPErrorDataPath, GPTokenErrorRes, GPTokenSuccess, GlobalpayConfig } from './globalpay.model';
import { DialogModule } from 'primeng/dialog';
import { CheckoutTabNames } from '../../../enums';
import { NgxAimPBlockableDivComponent } from '../../p-blockable-div/ngx-aim-p-blockable-div.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';

declare const GlobalPayments: any;

@Component({
  selector: 'ngx-aim-globalpay',
  standalone: true,
  imports: [
    DialogModule,
    NgxAimPBlockableDivComponent,
    BlockUIModule,
    ProgressSpinnerModule,
    ButtonModule,
    NgStyle,
    NgClass,
  ],
  templateUrl: './ngx-aim-globalpay.component.html',
  styleUrls: ['./ngx-aim-globalpay.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxAimGlobalpayComponent implements OnInit {
  @Input({ required: true }) checkoutService: GenericCheckoutService | undefined;
  @Output() success = new EventEmitter<boolean>();

  globalpayConfig: GlobalpayConfig = {
    payFieldsUrl: '',
    payFieldsApiKey: '',
    environment: 'prod',
  }
  cardData: GPTokenSuccess | undefined;
  cardValid = false;
  expiredValid = false;
  cvvValid = false;
  displayErrorDialog = false;
  isLoading = false;
  errors: string[] = [];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private globalpayService: NgxAimGlobalpayService,
    private httpErrorService: HttpErrorService,
    private changeRef: ChangeDetectorRef,
    private paymentService: PaymentService,
  ) {}

  ngOnInit() {
    this.initConfig()
  }

  initConfig() {
    this.globalpayService.getConfig().subscribe({
      next: (config) => this.afterConfigInit(config),
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  async afterConfigInit(config: GlobalpayConfig) {
    this.globalpayConfig = config;
    await this.initGlobalpay()
    this.initForm();
  }

  initGlobalpay(): Promise<void> {
    const headerEl = this.document.getElementsByTagName('head')[0];
    const element = this.document.createElement('script');
    element.id = 'app-globalpay';
    element.src = this.globalpayConfig.payFieldsUrl;
    element.type = 'text/javascript';
    headerEl.appendChild(element);
    return new Promise((resolve) => {
      element.onload = resolve;
    });
  }

  initForm() {
    GlobalPayments.configure({
      'X-GP-Api-Key': this.globalpayConfig.payFieldsApiKey,
      'X-GP-Environment': this.globalpayConfig.environment,
      'enableAutocomplete': true
    });

    const cardForm = GlobalPayments.ui.form({
      fields: {
        'card-number': {
          target: '#card-number',
          placeholder: '•••• •••• •••• ••••'
        },
        'card-expiration': {
          target: '#card-expiration',
          placeholder: 'MM / YYYY'
        },
        'card-cvv': {
          target: '#card-cvv',
          placeholder: '•••'
        },
        'submit': {
          target: '#submit',
          text: 'Add Credit Card'
        }
      },
      styles: {
        'button': {
          'border-radius': '6px',
          'font-size': '1rem',
          'padding': '0.75rem',
          'background': '#3B82F6',
          'color': '#ffffff',
          'cursor': 'pointer',
        },
        'button:hover': {
          'background': '#2563eb',
        },
        'button:active': {
          'filter': 'brightness(125%)'
        },
        'input': {
          'color': '#495057',
          'border': '1px solid #ced4da',
          'border-radius': '6px',
          'font-size': '1rem',
          'padding': '0.75rem',
        },
        'input:focus, input:active': {
          'outline': 'none',
        },
        'input:hover': {
          'border-color': '#3b82f6 !important',
        },
        'input.invalid': {
          'border-color': '#ff8282'
        },
        '.card-number.card-type-visa': {
          'background': '#f3faff url(https://hps.github.io/token/gp-1.0.0/images/logo-visa@2x.png) no-repeat right',
          'background-position-y': '1px',
          'background-size': '70px 75px'
        },
        '.card-number.card-type-mastercard': {
          'background': '#f3faff url(https://hps.github.io/token/gp-1.0.0/images/logo-mastercard@2x.png) no-repeat right',
          'background-position-y': '5px',
          'background-size': '60px 64px'
        },
        '.card-number.card-type-discover': {
          'background': '#f3faff url(https://hps.github.io/token/gp-1.0.0/images/logo-discover@2x.png) no-repeat right',
          'background-position-y': '-5px',
          'background-size': '90px 96px'
        },
        '.card-number.card-type-jcb': {
          'background': '#f3faff url(https://hps.github.io/token/gp-1.0.0/images/logo-jcb@2x.png) no-repeat right',
          'background-position-y': '1px',
          'background-size': '48px 82px'
        },
        '.card-number.card-type-amex': {
          'background': '#f3faff url(https://hps.github.io/token/gp-1.0.0/images/logo-amex@2x.png) no-repeat right',
          'background-position-y': '-2px',
          'background-size': '55px 98px'
        },
      }
    });

    cardForm.on('click', () => {
      this.isLoading = true;
      this.changeRef.detectChanges();
    });

    cardForm.on('token-success', (res: GPTokenSuccess) => {
      this.onTokenSuccess(res);
    });

    cardForm.on('token-error', (res: GPTokenErrorRes) => {
      this.onTokenError(res);
    });

    GlobalPayments.on('error', (error: any) => {
      console.error('GlobalPayments Error', error);
    });

    cardForm.on('card-number-test', (res: GPDataValid) => {
      this.cardValid = res.valid;
      this.changeRef.detectChanges();
    });

    cardForm.on('card-expiration-test', (res: GPDataValid) => {
      this.expiredValid = res.valid;
      this.changeRef.detectChanges();
    });

    cardForm.on('card-cvv-test', (res: GPDataValid) => {
      this.cvvValid = res.valid;
      this.changeRef.detectChanges();
    });
  }

  onTokenSuccess(res: GPTokenSuccess) {
    this.paymentService.cardData.globalpay = res;
    this.cardData = res;
    this.isLoading = false;
    this.changeRef.detectChanges();
    if (this.checkoutService) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.success.emit(true);
    }
  }

  onTokenError(res: GPTokenErrorRes) {
    this.errors = [];
    res.error.detail.forEach(err => {
      this.errors.push(this.errorDataPathToFriendlyString(err.data_path));
    });
    this.isLoading = false;
    this.displayErrorDialog = true;
    this.changeRef.detectChanges();
  }

  onChangeCardClick() {
    this.cardData = undefined;
    this.paymentService.cardData.globalpay = undefined;
    this.changeRef.detectChanges();
  }

  errorDataPathToFriendlyString(dataPath: GPErrorDataPath) {
    switch (dataPath) {
      case '/card/card-expiration': {
        return 'The card expiration is invalid';
      }
      case '/card/card-number': {
        return 'The card number is invalid';
      }
      default: {
        return 'Invlid card information';
      }
    }
  }

  isSubmitButtonEnabled(): boolean {
    return this.cardValid && this.expiredValid;
  }
}
