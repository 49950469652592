import { inject } from '@angular/core';
import { UiService } from '../../../../services';
import { ImageInfo } from '../../../../models';

/** when extending this class be sure to implement
 * @Input()
 * override images: ImageInfo[] | undefined = [];
 */
export abstract class NgxAimProductGalleriaClass {
  uiService = inject(UiService);

  images: ImageInfo[] = [];
  isMobile$ = this.uiService.isMobile$;

  getContainerStyle(isMobile: boolean | null) {
    if (isMobile) {
      return { 'max-width': '100vw', 'margin-left': '-8px', 'margin-right': '-8px' };
    } else {
      return { 'max-width': '640px' }
    }
  }
}
