import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PriceFilter } from '../../../../models';
import { NgxPriceFilterClass } from './ngx-aim-price-filter.class';

@Component({
  selector: 'ngx-aim-price-filter',
  standalone: true,
  imports: [FormsModule, InputNumberModule, ButtonModule],
  templateUrl: './ngx-aim-price-filter.component.html',
  styleUrls: ['./ngx-aim-price-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimPriceFilterComponent extends NgxPriceFilterClass implements OnInit {
  @Input()
  override minMax: PriceFilter | undefined;

  ngOnInit() {
    this.init();
  }
}
