import { ViewContainerRef, QueryList, inject } from '@angular/core';
import { AccordionTab, AccordionTabOpenEvent } from 'primeng/accordion';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AppStateService, CartService, PaymentService, ShortTermRentalCheckoutService } from '../../../services';
import { AddressType, CheckoutTabNames, CheckoutType, PaymentType, PublicRoutes, TransactionType } from '../../../enums';
import { CheckoutTabsDisabled, CheckoutTotals } from '../../../models';

/** when extending this class be sure to implement ngOnInit, ngAfterViewInit,
 * @ViewChild('paymentForm', { read: ViewContainerRef })
 * override paymentFormRef!: ViewContainerRef;
 *
 * @ViewChildren('accordionTab')
 * override accordionTabs!: QueryList<AccordionTab>;
 */
export abstract class NgxAimRentalCheckoutClass {
  // ngOnInit() {
  //   this.init();
  // }

  // ngAfterViewInit(): void {
  //   this.afterViewInit();
  // }

  checkoutService = inject(ShortTermRentalCheckoutService);
  cartService = inject(CartService);
  router = inject(Router);
  appState = inject(AppStateService);
  paymentService = inject(PaymentService);

  paymentFormRef!: ViewContainerRef;
  accordionTabs!: QueryList<AccordionTab>;

  tabs = CheckoutTabNames;
  tabIndex$ = this.checkoutService.tabIndex$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  tabsDisabled$ = this.checkoutService.tabsDisabed$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals$ = this.checkoutService.totals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  isLoadingTotals$ = this.checkoutService.isLoadingTotals$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );
  totals: CheckoutTotals = {
    items: 0,
    shipping: 0,
    tax: 0,
    total: 0,
  }
  publicRoutes = PublicRoutes;
  tabsDisabled: CheckoutTabsDisabled = {};
  checkoutType = CheckoutType.SHORT_TERM_RENTAL;
  addressType = AddressType;
  cart = this.cartService.getCart();
  acceptPayments = true;

  init() {
    if (!this.appState.isBrowser) {
      return;
    }

    this.acceptPayments = this.appState.paymentType !== PaymentType.NONE;
    this.tabsDisabled$.subscribe((tabs) => {
      this.tabsDisabled = tabs;
    });
    this.totals$.subscribe(totals => {
      this.totals = totals;
    });
  }

  afterViewInit(): void {
    if (!this.appState.isBrowser) {
      return;
    }

    this.initPaymentForm(this.appState.paymentType);
    this.checkoutService.tabs = this.accordionTabs.map((tab, i) => ({ header: tab.header!, index: i }));
  }

  initPaymentForm(type: PaymentType) {
    this.paymentService.cardData.type = type;
    switch (type) {
      case PaymentType.AUTHORIZENET: {
        import('../../payment/authorizenet/ngx-aim-authorizenet.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimAuthorizenetComponent);
          comp.instance.checkoutService = this.checkoutService;
        });
        break;
      }
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        import('../../payment/globalpay/ngx-aim-globalpay.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimGlobalpayComponent);
          comp.instance.checkoutService = this.checkoutService;
        });
        break;
      }
      case PaymentType.TSYS_AUTH_ONLY: {
        import('../../payment/tsys/ngx-aim-tsys.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimTsysComponent);
          comp.instance.checkoutService = this.checkoutService;
          comp.instance.transactionType = TransactionType.SHORT_TERM_RENTAL;
        });
        break;
      }
    }
  }

  onTabOpen($event: AccordionTabOpenEvent) {
    this.checkoutService.setTabIndex($event.index);
  }
}