import { inject } from '@angular/core';
import { PublicRoutes } from '../../enums';
import { NgxAimAdminRoutes } from '../admin/ngx-aim-admin-admin.routes';
import { AppStateService, UiService } from '../../services';

export abstract class NgxAimFooterClass {
  uiService = inject(UiService);
  appState = inject(AppStateService);

  routes = PublicRoutes;
  adminRoutes = NgxAimAdminRoutes;
  isMobile$ = this.uiService.isMobile$;
  isTablet$ = this.uiService.isTablet$;
  businessInfo = this.appState.businessInfo;
  currentYear = new Date().getFullYear();
  defaultLocation = this.appState.getDefaultLocation();
}
