import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { BlockUIModule } from 'primeng/blockui';
import { NgxAimPBlockableDivComponent } from '../../p-blockable-div/ngx-aim-p-blockable-div.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgxAimDeliveryOptionsClass } from './ngx-aim-delivery-options.class';
import { AsyncPipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-delivery-options',
  standalone: true,
  imports: [
    RadioButtonModule, 
    FormsModule, 
    ButtonModule, 
    RippleModule,
    BlockUIModule,
    NgxAimPBlockableDivComponent,
    ProgressSpinnerModule,
    AsyncPipe,
    CurrencyPipe,
  ],
  templateUrl: './ngx-aim-delivery-options.component.html',
  styleUrls: ['./ngx-aim-delivery-options.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxAimDeliveryOptionsComponent extends NgxAimDeliveryOptionsClass {}
