import { Component, Input, OnInit } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';
import { CarouselName } from '../../enums';
import { NgxAimCarouselClass } from './ngx-aim-carousel.class';

@Component({
  selector: 'ngx-aim-carousel',
  standalone: true,
  imports: [CarouselModule],
  templateUrl: './ngx-aim-carousel.component.html',
  styleUrls: ['./ngx-aim-carousel.component.css'],
})
export class NgxAimCarouselComponent extends NgxAimCarouselClass implements OnInit {
  @Input() 
  override carouselName: CarouselName = CarouselName.MAIN;

  ngOnInit() {
    this.init()
  }
}
