import { NgClass, NgStyle } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { BlockableUI } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';

@Component({
  selector: 'ngx-aim-p-blockable-div',
  standalone: true,
  imports: [BlockUIModule, NgClass, NgStyle],
  template: '<div [ngStyle]="style" [ngClass]="class"><ng-content></ng-content></div>'
})
export class NgxAimPBlockableDivComponent implements BlockableUI {
  @Input() style: any;
  @Input() class: any;

  constructor(private el: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}