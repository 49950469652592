import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxAimAdminPrimengModule } from '../../../ngx-aim-admin.primeng.module';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { NgxAimAdminAppPostsService } from '../ngx-aim-admin-admin-app-posts.service';
import { HttpErrorService } from '../../../../../services';
import { AppPost, AppUploadEvent } from '../../../../../models';
import { MessageService } from 'primeng/api';
import { NgxAimPBlockableDivComponent } from '../../../../p-blockable-div/ngx-aim-p-blockable-div.component';
import { BlockUIModule } from 'primeng/blockui';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'ngx-aim-admin-edit-app-post',
  standalone: true,
  imports: [
    NgxAimAdminPrimengModule,
    ReactiveFormsModule,
    QuillModule,
    NgxAimPBlockableDivComponent,
    BlockUIModule,
    FileUploadModule
  ],
  templateUrl: './ngx-aim-admin-edit-app-post.component.html',
  styleUrls: ['./ngx-aim-admin-edit-app-post.component.css', '../../../../../3rd-party/quill/quill.snow.css'],
  encapsulation: ViewEncapsulation.None
})
export class NgxAimAdminEditAppPostComponent implements OnInit {
  route = inject(ActivatedRoute);
  fb = inject(FormBuilder);
  appPostService = inject(NgxAimAdminAppPostsService);
  httpErrorService = inject(HttpErrorService);
  messageService = inject(MessageService);

  form = this.fb.group({
    id: this.fb.control<number | null>(null),
    category: this.fb.control<string>(''),
    slug: this.fb.control<string>(''),
    title: this.fb.control<string>(''),
    subTitle: this.fb.control<string>(''),
    image: this.fb.control<string>(''),
    body: this.fb.control<string>(''),
  });

  headers: any;
  uploadProgress = 0;
  downloadProgress = 0;
  isSaving = false;
  isLoading = false;

  constructor() {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== 'new') {
      this.getPost(+id);
    }
  }

  getPost(id: number) {
    this.isLoading = true;
    this.appPostService.findOne(id).subscribe({
      next: (appPost) => this.fillForm(appPost),
      error: (err) => this.onError(err, 'Could not load post')
    });
  }

  fillForm(appPost: AppPost) {
    this.isLoading = false;
    this.headers = { id: appPost.id };
    this.form.setValue(appPost);
  }

  onSaveClick() {
    this.isSaving = true;
    this.appPostService.save(this.form.getRawValue()).subscribe({
      next: (event) => this.onProgressUpdate(event),
      error: (err) => this.onError(err, 'Could not save post')
    });
  }

  onProgressUpdate(event: HttpEvent<AppPost>) {
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        this.uploadProgress = Math.round((100 * event.loaded / event.total!) / 2);
        break;
      }
      case HttpEventType.DownloadProgress: {
        this.downloadProgress = Math.round((100 * event.loaded / event.total!) / 2);
        break;
      }
      case HttpEventType.Response: {
        this.uploadProgress = 0;
        this.downloadProgress = 0;
        this.onSuccess(event.body);
        break;
      }
    }
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(appPost: AppPost | null) {
    if (!appPost) return;
    this.form.patchValue(appPost);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Post saved',
      key: 'app-toast'
    });
  }

  onUpload(event: AppUploadEvent) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image Uploaded', key: 'app-toast' });
    this.form.patchValue({ image: event.originalEvent.body.url });
  }

  onUploadError(event: any) {
    this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Image could not be uploaded', key: 'app-toast' });
  }
}
