import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgxAimCategoryFilterComponent } from './category-filter/ngx-aim-category-filter.component';
import { NgxAimAttributeFilterComponent } from './attribute-filter/ngx-aim-attribute-filter.component';
import { NgxAimPriceFilterComponent } from './price-filter/ngx-aim-price-filter.component';
import { NgxAimProductFilterClass } from './ngx-aim-product-filter.class';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-product-filter',
  standalone: true,
  imports: [NgxAimCategoryFilterComponent, NgxAimAttributeFilterComponent, NgxAimPriceFilterComponent, AsyncPipe],
  templateUrl: './ngx-aim-product-filter.component.html',
  styleUrls: ['./ngx-aim-product-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimProductFilterComponent extends NgxAimProductFilterClass {}
