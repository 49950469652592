import { inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { NgxAimViewOrderComponent } from './view-order/ngx-aim-view-order.component';
import { OrderService } from '../../services';
import { MatchModesPrime, PublicRoutes } from '../../enums';
import { AppLazyLoadEvent } from '../../models';

/** when extending this class be sure to implement
 * @ViewChild('dt') 
 * override table: Table | undefined; 
 */
export abstract class NgxAimCustomerOrdersClass {
  entityService = inject(OrderService);
  dialogService = inject(DialogService);

  table: Table | undefined;

  routes = PublicRoutes;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isSyncing = false;

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(NgxAimViewOrderComponent, {
      data: entityId,
      header: 'Order',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }
}