import { Component, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToolbarModule } from 'primeng/toolbar';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { AppLazyLoadEvent, AppPost } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { MatchModesPrime } from '../../../../enums';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { NgxAimAdminAppPostsService } from './ngx-aim-admin-admin-app-posts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAimAdminRoutes } from '../../ngx-aim-admin-admin.routes';
import { AsyncPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-app-posts',
  standalone: true,
  imports: [
    FormsModule,
    ToolbarModule,
    ConfirmPopupModule,
    DialogModule,
    TableModule,
    TagModule,
    DropdownModule,
    ButtonModule,
    CheckboxModule,
    AsyncPipe,
    NgClass,
  ],
  templateUrl: './ngx-aim-admin-app-posts.component.html',
  styleUrls: ['./ngx-aim-admin-app-posts.component.css', '../../admin.scss'],
  providers: [ConfirmationService]
})
export class NgxAimAdminAppPostsComponent {
  @ViewChild('dt') table: Table | undefined;

  router = inject(Router);
  route = inject(ActivatedRoute);

  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedData: AppPost[] = [];
  selectedOptions: string[] = [];
  showOptions = false;

  constructor(
    private entityService: NgxAimAdminAppPostsService,
    private confirmationService: ConfirmationService,
    private httpErrorService: HttpErrorService,
    private messageService: MessageService
  ) {}

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  onNewClick() {
    this.openEntity();
  }

  openEntity(entityId?: number) {
    if (entityId) {
      this.router.navigate([NgxAimAdminRoutes.EDIT, entityId], { relativeTo: this.route });
    } else {
      this.router.navigate([NgxAimAdminRoutes.EDIT, 'new'], { relativeTo: this.route });
    }
  }

  onDeleteClick(event: Event): void {
    this.confirmationService.confirm({
      key: 'onDeleteClick',
      target: event.target!,
      message: `Are you sure that you want to delete (${this.selectedData.length}) ${this.singleOrPluralRecords}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteSelectedData();
      }
    });
  }

  deleteSelectedData() {
    const ids = this.selectedData.map(data => data.id) as number[];
    this.entityService.delete(ids).subscribe({
      next: (affectedRows) => this.deleteSuccess(affectedRows),
      error: (err) => this.deleteError(err, 'Could not delete records')
    });
  }

  deleteSuccess(affectedRows: number) {
    if (affectedRows === this.selectedData.length) {
      this.messageService.add({
        severity: 'success',
        summary: 'Confirmed',
        detail: `You have deleted (${this.selectedData.length}) ${this.singleOrPluralRecords}`,
        key: 'app-toast',
        life: 5000
      });
      this.selectedData = [];
      this.onLazyLoad(this.lastLazyLoadEvent);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: `Some records couldn't be deleted`,
        key: 'app-toast',
        life: 5000
      });
      this.selectedData = [];
      this.onLazyLoad(this.lastLazyLoadEvent);
    }
  }

  deleteError(err: any, summary: string) {
    this.httpErrorService.onHttpError(err, summary);
  }

  onOptionsClose(action: 'cancel' | 'update') {
    if (action === 'cancel') {
      this.selectedOptions = [];
    }
    if (action === 'update') {
      this.onLazyLoad(this.lastLazyLoadEvent);
    }
    this.showOptions = false;
  }

  get singleOrPluralRecords(): string {
    return `record${this.selectedData.length > 1 ? 's' : ''}`;
  }
}
