import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { GlobalpaySettingsDto } from '../../../../models';
import { HttpErrorService } from '../../../../services';
import { GlobalpaySettingNames } from '../../../../enums';
import { NgxAimAdminGlobalpayService } from './ngx-aim-admin-globalpay.service';

@Component({
  selector: 'ngx-aim-admin-globalpay',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, ButtonModule, ProgressSpinnerModule],
  templateUrl: './ngx-aim-admin-global-pay.component.html',
  styleUrls: ['./ngx-aim-admin-global-pay.component.scss', '../../admin.scss']
})
export class NgxAimAdminGlobalpayComponent {
  form = this.fb.group({
    [GlobalpaySettingNames.MERCHANT_NAME]: this.fb.control<string>(''),
    [GlobalpaySettingNames.SITE_ID]: this.fb.control<string>(''),
    [GlobalpaySettingNames.KEY]: this.fb.control<string>(''),
  });

  isLoading = false;
  isSaving = false;
  constructor(
    private paymentService: NgxAimAdminGlobalpayService,
    private httpErrorService: HttpErrorService,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {
    this.paymentService.getPaymentSettings().subscribe({
      next: (settings) => this.form.patchValue(settings),
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  onSaveClick() {
    this.isSaving = true;
    const settings: GlobalpaySettingsDto = {
      [GlobalpaySettingNames.MERCHANT_NAME]: this.form.controls[GlobalpaySettingNames.MERCHANT_NAME].value || '',
      [GlobalpaySettingNames.SITE_ID]: this.form.controls[GlobalpaySettingNames.SITE_ID].value || '',
      [GlobalpaySettingNames.KEY]: this.form.controls[GlobalpaySettingNames.KEY].value || '',
    }

    this.paymentService.savePaymentSettings(settings).subscribe({
      next: () => this.onSaveSuccess(),
      error: (err) => this.onSaveError(err),
    });
  }

  onSaveSuccess() {
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Global Pay settings saved',
      key: 'app-toast'
    });
  }

  onSaveError(err: any) {
    this.isSaving = false;
    this.httpErrorService.onHttpError(err, 'Could not save global pay settings');
  }

  onClearClick() {
    this.form.patchValue({
      [GlobalpaySettingNames.MERCHANT_NAME]: '',
      [GlobalpaySettingNames.SITE_ID]: '',
      [GlobalpaySettingNames.KEY]: '',
    });
  }
}
