<div class="grid">
  <div class="col-12">
    <p-dataView #dv [value]="lineItems">
      <ng-template let-lineItems pTemplate="listItem">
        @for (lineItem of lineItems; track lineItem.id; let first = $first) {
          <div class="col-12">
            <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
              <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" [src]="lineItem.item.thumbSrc | hasImage" [alt]="lineItem.ProducName" />
              <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                <div class="text-2xl font-bold text-900">{{ lineItem.ProductName }}</div>
                <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                  <span class="text-2xl font-semibold">
                    {{(lineItem.Price * lineItem.Qty) | currency}}
                  </span>
                  <span>
                    Qty: {{lineItem.Qty}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </ng-template>
    </p-dataView>
  </div>
</div>
