export enum PaymentType {
  NONE = 'none',
  PAYPAL = 'paypal',
  AUTHORIZENET = 'authorizenet',
  GLOBALPAY = 'globalpay',
  GLOBALPAY_AUTH_ONLY = 'globalpay_auth_only',
  TSYS_AUTH_ONLY = 'tsys_auth_only',
  FORTIS_AUTH_ONLY = 'fortis_auth_only',
}

export const PaymentTypes = [
  PaymentType.NONE,
  PaymentType.PAYPAL,
  PaymentType.AUTHORIZENET,
  PaymentType.GLOBALPAY,
  PaymentType.GLOBALPAY_AUTH_ONLY,
  PaymentType.TSYS_AUTH_ONLY,
  PaymentType.FORTIS_AUTH_ONLY,
];

/** The names below are what are used in the globalpay docs.
 * How ever the document the client will receive call these properties
 * by different names. The comments show what the client will see
 */
export enum GlobalpaySettingNames {
  /** X-Web ID */
  MERCHANT_NAME = 'merchant_name',
  /** Terminal ID */
  SITE_ID = 'site_id',
  /** Authkey */
  KEY = 'key',
}

export enum TsysSettingNames {
  USER_ID = 'user_id',
  PASSWORD = 'password',
  TRANSACTION_KEY = 'transaction_key',
  MID = 'mid',
}

/**
 * Status of payment actions
 *
 * @readonly
 * @enum {string}
 */
export enum PaymentStatus {
  /** The order hasn't had any payment transactions ran */
  NONE = 'none',
  /** Transaction has been completed */
  APPROVED = 'approved',
  /** Processor declined the transaction */
  DECLINED = 'declined',
  /** There was an error while trying to make a transaction */
  ERROR = 'error',
  /** The transaction was voided */
  VOIDED = 'voided',
  /** There was an error while trying to void a transation */
  VOID_ERROR = 'void_error',
  /** The transaction has been refunded */
  REFUNDED = 'refunded',
  /** There was an error trying to refund the transaction */
  REFUND_ERROR = 'refund_error',
}

/**
 * List of actions that are performed in payment transactions
 *
 * @readonly
 * @enum {string}
 */
export enum PaymentAction {
  /** Charge a credit card */
  CHARGE = 'charge',
  /** Void a transaction */
  VOID = 'void',
  /** Obtain a credit card token */
  OBTAIN_TOKEN = 'obtain_token',
  /** Return charge to credit card */
  REFUND = 'refund',
  /** Generate a TSYS manifest */
  OBTAIN_MANIFEST = 'optain_manifest',
}

/**
 * List of type of transactions
 *
 * @readonly
 * @enum {string}
 */
export enum TransactionType {
  /** Purchasing items */
  ORDER = 'order',
  /** Short term rental */
  SHORT_TERM_RENTAL = 'short_term_rental',
  /** Contract / long term rental */
  CONTRACT = 'contract',
  /** Update credit card token */
  UPDATE_CC_TOKEN = 'update_cc_token',
  /** Obtains a key that is used for the lifetime of the account */
  LIFETIME_KEY = 'lifetime_key',
}
