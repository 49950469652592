import { ChangeDetectorRef, inject } from '@angular/core';
import { ItemForDisplay, STRRate } from '../../../models';
import { ShortTermRentalService } from '../../../services';

/** when extending this class be sure to implement ngOnInit,
 * @Input({ required: true })
 * override item!: ItemForDisplay;
 * 
 * @Input({ required: true })
 * override isRental!: boolean;
 */
export class NgxAimProductPriceClass {
  // ngOnInit() {
  //   this.init();
  // }

  rentalService = inject(ShortTermRentalService);
  changeRef = inject(ChangeDetectorRef);

  item!: ItemForDisplay;
  isRental!: boolean;

  rates: STRRate[] = [];
  selectedRate: STRRate | undefined;
  error = '';
  isLoading = true;

  init() {
    if (this.isRental) {
      this.getRates();
    }
  }

  getRates() {
    this.rentalService.getRateForItem(this.item.Pk).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.rates = res.Rates;
        this.selectedRate = res.Rates[0];
        this.changeRef.detectChanges();
      },
      error: (err) => {
        this.isLoading = false;
        this.error = 'Please call for pricing';
        this.changeRef.detectChanges();
      }
    });
  }
}
