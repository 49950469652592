import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, FindAllResult, Item } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminItemsService extends GenericSearchService<Item> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/items`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Items');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Item>> {
    return this.http.post<FindAllResult<Item>>(`${this.baseUrl}/all`, dto);
  }
}
