import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgxAimService } from '../ngx-aim.service';
import { HttpClient } from '@angular/common/http';
import { InitData } from '../models/init-data.model';
import { MenuItem } from 'primeng/api';
import { PaymentType, StoreType } from '../enums';
import { BusinessSettings, EMPTY_BUSINESS_SETTINGS, EMPTY_LOCATION, Location } from '../models';

export function initApp(appStateService: AppStateService) {
  return () => {
    return new Promise<void>(async (resolve) => {
      await appStateService.initApp();
      resolve();
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  private baseUrl = `${this.ngxAimService.getApiUrl()}`;

  #isPlatformBrowser = false;
  public get isPlatformBrowser(): boolean {
    return this.#isPlatformBrowser;
  }

  #categories: MenuItem[] = [];
  public get categories(): MenuItem[] {
    return this.#categories;
  }

  #storeType: StoreType = StoreType.GENERIC;
  public get storeType(): StoreType {
    return this.#storeType;
  }

  #paymentType: PaymentType = PaymentType.NONE;
  public get paymentType(): PaymentType {
    return this.#paymentType;
  }

  #chargeStr: boolean = false;
  public get chargeStr(): boolean {
    return this.#chargeStr;
  }

  #inStorePickup: boolean = false;
  public get inStorePickup(): boolean {
    return this.#inStorePickup;
  }

  #guestCheckout: boolean = false;
  public get guestCheckout(): boolean {
    return this.#guestCheckout;
  }

  #businessSettings: BusinessSettings = EMPTY_BUSINESS_SETTINGS;
  public get businessInfo(): BusinessSettings {
    return this.#businessSettings;
  }

  #locations: Location[] = [];
  public getLocations(): Location[] {
    return this.#locations;
  }

  #defaultLocation: Location = EMPTY_LOCATION;
  public getDefaultLocation(): Location {
    return this.#defaultLocation;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngxAimService: NgxAimService,
    private http: HttpClient,
  ) {
    this.#isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  public get isBrowser(): boolean {
    return this.isPlatformBrowser;
  }

  public async initApp(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.http.get<InitData>(`${this.baseUrl}/init-data`).subscribe({
        next: (initData) => {
          this.#categories = initData.categories;
          this.#storeType = initData.storeType;
          this.#paymentType = initData.paymentType;
          this.#businessSettings = initData.businessSettings;
          this.#chargeStr = initData.chargeStr;
          this.#inStorePickup = initData.inStorePickup;
          this.#guestCheckout = initData.guestCheckout;
          this.#locations = initData.locations;
          this.#defaultLocation = initData.locations?.find((location) => location.isDefault) || EMPTY_LOCATION;
          resolve();
        },
        error: (err) => resolve(),
      });
    });
  }
}
