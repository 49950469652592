import { inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { distinctUntilChanged } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContractsService } from '../../services';
import { ContractStepNames, PublicRoutes } from '../../enums';
import { ContractStepsDisabled } from '../../models';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimContractsClass {
  // ngOnInit() {
  //   this.init();
  // }

  contractsService = inject(ContractsService);

  stepsDisabled$ = this.contractsService.stepsDisabed$.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed()
  );

  items: MenuItem[] = [
    {
      label: ContractStepNames.SELECT_SCHOOL,
      routerLink: PublicRoutes.SELECT_SCHOOL,
      disabled: false,
    },
    {
      label: ContractStepNames.SELECT_INSTRUMENT,
      routerLink: PublicRoutes.SELECT_INSTRUMENT,
      disabled: true,
    },
    {
      label: ContractStepNames.OPTIONS_ACCESSORIES,
      routerLink: PublicRoutes.OPTIONS_ACCESSORIES,
      disabled: true,
    },
    {
      label: ContractStepNames.PAYMENT,
      routerLink: PublicRoutes.PAYMENT,
      disabled: true,
    }
  ];

  init() {
    this.contractsService.stepsDisabed$.subscribe(step => {
      this.onStepChange(step);
    });
  }

  onStepChange(steps: ContractStepsDisabled) {
    const items = [...this.items]; // force p-steps to recognize the updated disabled property.
    for (const key of Object.keys(steps)) {
      const item = items.find(item => item.label === key);
      if (item) {
        item.disabled = steps[key];
      }
    }
    this.items = items;
  }
}