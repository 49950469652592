import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxPostClass } from './ngx-posts.class';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'ngx-aim-posts',
  standalone: true,
  imports: [],
  templateUrl: './ngx-aim-posts.component.html',
  styleUrl: './ngx-aim-posts.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class NgxAimPostsComponent extends NgxPostClass implements OnInit {
  ngOnInit() {
    const category = this.route.snapshot.paramMap.get('category');
    if (category) {
      this.getPosts(category);
    }
  }
}
