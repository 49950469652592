import { inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService, AuthenticationService, UiService } from '../services';
import { PublicRoutes, UserType } from '../enums';
import { NgxAimAdminRoutes } from './admin/ngx-aim-admin-admin.routes';
import { CurrentUser } from '../models';
import { take } from 'rxjs';

/** when extending this class be sure to implement ngOnInit,
 *  @Input()
 *  override isInCheckout = false;
 */
export class NgxAimSignInClass {
  // ngOnInit() {
  //   this.init();
  // }

  isInCheckout = false;

  fb = inject(FormBuilder);
  authService = inject(AuthenticationService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  uiService = inject(UiService);
  appState = inject(AppStateService);

  routes = PublicRoutes;
  adminRoutes = NgxAimAdminRoutes;

  form = this.fb.nonNullable.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  });

  guestForm = this.fb.nonNullable.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  guestCheckout = this.appState.guestCheckout;
  showGuestForm = false;
  isLogingIn = false;
  isGuestLogingIn = false;
  isLoadingGuest = false;
  isMobile$ = this.uiService.isMobile$;
  error = '';
  guestError = '';
  returnUrl = ''

  init() {
    this.route.queryParamMap.pipe(take(1)).subscribe((params) => {
      this.returnUrl = params.get('returnUrl') || '';
      this.isInCheckout = params.get('isCheckout') === 'true' ? true : false;
    });
  }

  onSubmit() {
    if (!this.form.value.email) {
      this.form.controls.email.markAsTouched();
      return;
    }

    if (!this.form.value.password) {
      this.form.controls.password.markAllAsTouched();
      return;
    }

    this.error = '';
    this.isLogingIn = true;
    this.authService.login(this.form.value.email, this.form.value.password).subscribe({
      next: (currentUser) => this.onLoginSuccess(currentUser),
      error: (err) => this.onLoginError(err)
    });
  }

  onLoginSuccess(currentUser: CurrentUser) {
    this.isLogingIn = false;
    if (currentUser.userType === UserType.ADMIN) {
      this.router.navigateByUrl(`${this.adminRoutes.ROOT}`);
      return;
    }

    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  onLoginError(err: any) {
    this.isLogingIn = false;
    this.error = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }

  onGuestContinueClick() {
    this.showGuestForm = true;
  }

  onGuestSubmit() {
    if (!this.guestForm.value.email) {
      this.form.controls.email.markAsTouched();
      return;
    }

    if (!this.guestForm.value.firstName) {
      this.guestForm.controls.firstName.markAllAsTouched();
      return;
    }

    if (!this.guestForm.value.lastName) {
      this.guestForm.controls.lastName.markAllAsTouched();
      return;
    }

    this.guestError = '';
    this.isGuestLogingIn = true;
    this.authService.createGuestUser(
      this.guestForm.value.firstName,
      this.guestForm.value.lastName,
      this.guestForm.value.email,
    ).subscribe({
      next: (currentUser) => this.onGuestSuccess(currentUser),
      error: (err) => this.onGuestError(err)
    });
  }

  onGuestSuccess(currentUser: CurrentUser) {
    this.isGuestLogingIn = false;
    if (currentUser.userType === UserType.ADMIN) {
      this.router.navigateByUrl(`${this.adminRoutes.ROOT}`);
      return;
    }

    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['/']);
    }
  }

  onGuestError(err: any) {
    this.isGuestLogingIn = false;
    this.guestError = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }


  get fc() { return this.form.controls; }
  get guestFc() { return this.guestForm.controls; }
}
