@if (!isRental) {
  <span class="text-2xl font-semibold">{{ item.ActiveePrice | currency }}</span>
}
@else {
  @if (!isLoading) {
    @if (error) {
      {{error}}
    }
    @else {
      <p-dropdown [options]="rates" [(ngModel)]="selectedRate" optionLabel="Desc" placeholder="Select a Freqneucy">
        <ng-template pTemplate="selectedItem">
          @if (selectedRate) {
            <div class="flex align-items-center gap-2">
              <div>{{ selectedRate.Desc }} {{selectedRate.Amt | currency}}</div>
            </div>
          }
        </ng-template>
        <ng-template let-rate pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ rate.Desc }} {{rate.Amt | currency}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    }
  }
  @else {
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  }
}
