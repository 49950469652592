import { Component } from '@angular/core';

@Component({
  selector: 'ngx-aim-admin-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './ngx-aim-admin-dashboard.component.html',
  styleUrls: ['./ngx-aim-admin-dashboard.component.scss']
})
export class NgxAimAdminDashboardComponent {

}
