import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SubCategory } from '../../../../models';
import { NgxAimRentalPriceClass } from './ngx-aim-rental-price.class';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'ngx-aim-rental-price',
  standalone: true,
  imports: [ProgressSpinnerModule, CurrencyPipe],
  templateUrl: './ngx-aim-rental-price.component.html',
  styleUrls: ['./ngx-aim-rental-price.component.scss']
})
export class NgxAimRentalPriceComponent extends NgxAimRentalPriceClass implements OnInit {
  @Input()
  override itemPk: number | undefined;

  @Input()
  override subCategory: SubCategory | undefined

  ngOnInit() {
    this.init();
  }
}
