<p-blockUI [target]="addressForm" [blocked]="isLoading || isSaving" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<ngx-aim-p-blockable-div #addressForm>
  <div>
    @if (showUsingShippingAsBilling) {
      <div class="flex align-content-center gap-4">
        <p-inputSwitch (onChange)="onUseShippingForBillingChange($event)"></p-inputSwitch>
        <div>Use shipping address for billing</div>
      </div>
    }

    <div class="col-12 mt-4">
      <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="firstname" formControlName="firstName" />
            <label htmlFor="firstname">First Name</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="lastname" formControlName="lastName" />
            <label htmlFor="lastName">Last Name</label>
          </span>
        </div>
        @if (storeType === storeTypes.FIREARMS) {
          <div class="field col-12 md:col-6">
            <span class="p-float-label p-input-icon-right">
              <i class="pi pi-info-circle" (click)="middleNameOp.toggle($event)"></i>
              <input pInputText id="middleName" formControlName="middleName" tooltipPosition="top" [pTooltip]="middleNameInfo" />
              <label htmlFor="middleName">Middle Name</label>
            </span>
          </div>
        }
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="company" formControlName="company" />
            <label htmlFor="company">Company</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="phone" formControlName="phone" />
            <label htmlFor="phone">phone</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="address1" formControlName="address1" />
            <label htmlFor="address1">Address</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="address2" formControlName="address2" />
            <label htmlFor="address2">Addrees 2</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="city" formControlName="city" />
            <label htmlFor="city">City</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown [options]="(states$ | async)!"
                        [lazy]="true"
                        [filter]="true"
                        [autoDisplayFirst]="false"
                        optionLabel="name"
                        optionValue="abbreviation"
                        formControlName="state"
                        filterBy="name">
              <ng-template let-state pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>{{ state.name }}</div>
                </div>
              </ng-template>
              <ng-template let-state pTemplate="header">
                @if (storeType === storeTypes.FIREARMS) {
                  <div class="flex align-items-center gap-2 state-header">
                    <i class="pi pi-info-circle ml-4" (click)="middleNameOp.toggle($event)"></i>
                    <p class="mt-4">If your state is not in this list we are unable to ship to your locaton</p>
                  </div>
                }
              </ng-template>
            </p-dropdown>
            <label htmlFor="state">State</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <input pInputText id="zip" formControlName="zip" />
            <label htmlFor="zip">Zip</label>
          </span>
        </div>
      </form>
    </div>
    <p-button (onClick)="onUseThisAddressClick()" [loading]="isSaving" label="Use this address" class="ml-2"></p-button>
  </div>
</ngx-aim-p-blockable-div>

<p-overlayPanel #middleNameOp>
  <p>{{middleNameInfo}}</p>
</p-overlayPanel>

<p-toast></p-toast>
<p-confirmDialog #cd>
  <ng-template pTemplate="footer">
      <button type="button" pButton label="OK" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
