import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { ImageInfo } from '../../../../models';
import { NgxAimProductGalleriaClass } from './ngx-aim-product-galleria.class';

@Component({
  selector: 'ngx-aim-product-galleria',
  standalone: true,
  imports: [GalleriaModule],
  templateUrl: './ngx-aim-product-galleria.component.html',
  styleUrls: ['./ngx-aim-product-galleria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxAimProductGalleriaComponent extends NgxAimProductGalleriaClass {
  @Input()
  override images: ImageInfo[] = [];
}
