import { Injectable } from '@angular/core';
import { ShipEngineRateEstimate } from './delivery-options.model';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgxAimService } from '../../../ngx-aim.service';
import { Ffl, ItemForDisplay, ItemInventoryType } from '../../../models';
import { HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimDeliveryOptionsService {
  baseUrl = `${this.ngxAimService.getApiUrl()}/shipping`;

  private selectedDeliveryOptionSubject = new BehaviorSubject<ShipEngineRateEstimate>({
    rate_id: '0',
    service_type: 'No options avaible',
    total_amount: 0,
    delivery_days: 0,
  });
  public selectedDeliveryOption$ = this.selectedDeliveryOptionSubject.asObservable();
  public getSelectedDeliveryOption(): ShipEngineRateEstimate {
    return this.selectedDeliveryOptionSubject.value;
  }
  public setSelectedDeliveryOption(option: ShipEngineRateEstimate) {
    this.selectedDeliveryOptionSubject.next(option);
  }

  private selectedSerializedDeliveryOptionSubject = new BehaviorSubject<ShipEngineRateEstimate>({
    rate_id: '0',
    service_type: 'No options avaible',
    total_amount: 0,
    delivery_days: 0,
  });
  public selectedSerializedDeliveryOption$ = this.selectedSerializedDeliveryOptionSubject.asObservable();
  public getSelectedSerializedDeliveryOption(): ShipEngineRateEstimate {
    return this.selectedSerializedDeliveryOptionSubject.value;
  }
  public setSelectedSerializedDeliveryOption(option: ShipEngineRateEstimate) {
    this.selectedSerializedDeliveryOptionSubject.next(option);
  }

  private deliveryOptionsSubject = new BehaviorSubject<ShipEngineRateEstimate[]>([]);
  public deliveryOptions$ = this.deliveryOptionsSubject.asObservable();

  private serializedDeliveryOptionsSubject = new BehaviorSubject<ShipEngineRateEstimate[]>([]);
  public serializedDeliveryOptions$ = this.serializedDeliveryOptionsSubject.asObservable();

  private loadingDeliveryOptionsSubject = new BehaviorSubject<boolean>(false);
  public loadingDeliveryOptions$ = this.loadingDeliveryOptionsSubject.asObservable();

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private httpErrorService: HttpErrorService
  ) {}

  getDeliveryOptions(itemType: ItemInventoryType, cartItems: ItemForDisplay[], ffl: Partial<Ffl> = {}) {
    this.loadingDeliveryOptionsSubject.next(true);
    this.http.post<ShipEngineRateEstimate[]>(`${this.baseUrl}/rate-estimate`, { itemType, cartItems, ffl }).subscribe({
      next: (deliveryOptions) => this.setDeliveryOptions(itemType, deliveryOptions),
      error: (err) => this.onGetDeliveryOptionsError(err)
    });
  }

  setDeliveryOptions(itemType: ItemInventoryType, options: ShipEngineRateEstimate[]) {
    if (itemType === ItemInventoryType.SERIALIZED) {
      this.serializedDeliveryOptionsSubject.next(options)
    } else {
      this.deliveryOptionsSubject.next(options)
    }
    this.loadingDeliveryOptionsSubject.next(false);
  }

  onGetDeliveryOptionsError(err: any) {
    this.loadingDeliveryOptionsSubject.next(false);
    this.httpErrorService.onHttpError(err, 'Could not get delivery options. Please try again');
  }
}
