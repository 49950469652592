import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, CustomerDefaults, FindAllResult, SetCustomerDefaultDto } from '../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminCustomerDefaultsService extends GenericSearchService<CustomerDefaults> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/customer-defaults`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Customer defaults');
  }

  sync(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/sync`, {});
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<CustomerDefaults>> {
    return this.http.post<FindAllResult<CustomerDefaults>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<CustomerDefaults> {
    return this.http.get<CustomerDefaults>(`${this.baseUrl}/customer-default/${id}`);
  }

  setDefault(dto: SetCustomerDefaultDto): Observable<CustomerDefaults> {
    return this.http.post<CustomerDefaults>(`${this.baseUrl}/set-default`, dto);
  }
}
