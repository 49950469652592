import { inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { CheckoutTabNames, CheckoutType, PaymentType, StoreType, AddressType } from '../../../enums';
import { AppStateService, CartService, CountryStateService, GenericCheckoutService, HttpErrorService } from '../../../services';
import { ItemInventoryType, SaveAddressDto, ShipEngineAddressDto, ShipEngineAddressValidateResponse, UserAddress } from '../../../models';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { NgxAimAddressService } from './ngx-aim-address.service';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxAimVerifyAddressComponent } from './verify-address/ngx-aim-verify-address.component';
import { ConfirmationService } from 'primeng/api';

/** when extending this class be sure to implement ngOnInit,
 * @Input() 
 * override type: AddressType = AddressType.SHIPPING;
 * 
 * @Input({ required: true })
 * override checkoutService!: GenericCheckoutService;
 * 
 * @Input({ required: true })
 * override checkoutType: CheckoutType = CheckoutType.PURCHASE;
 */
export abstract class NgxAimAddressClass {
  fb = inject(NonNullableFormBuilder);
  httpErrorService = inject(HttpErrorService);
  cartService = inject(CartService);
  appState = inject(AppStateService);
  countryStateService = inject(CountryStateService);
  addressService = inject(NgxAimAddressService);
  dialogService = inject(DialogService);
  confirmationService = inject(ConfirmationService);

  type: AddressType = AddressType.SHIPPING;
  checkoutService!: GenericCheckoutService;
  checkoutType: CheckoutType = CheckoutType.PURCHASE;

  states$ = this.countryStateService.states$;
  checkoutTabs = CheckoutTabNames;
  storeTypes = StoreType;
  storeType: StoreType = StoreType.GENERIC;
  middleNameInfo = 'You must provide your full middle name. If you only have an initial for your middle name, enter that letter. If you do not have a middle name leave it blank.';
  isSaving = false;
  isLoading = false;
  showUsingShippingAsBilling = false;

  form = this.fb.group({
    firstName: this.fb.control<string>(''),
    lastName: this.fb.control<string>(''),
    middleName: this.fb.control<string>(''),
    company: this.fb.control<string>(''),
    phone: this.fb.control<string>(''),
    address1: this.fb.control<string>(''),
    address2: this.fb.control<string>(''),
    city: this.fb.control<string>(''),
    state: this.fb.control<string>(''),
    zip: this.fb.control<string>(''),
  });

  init() {
    this.checkoutService.getAddress(this.type).subscribe({
      next: (address) => this.form.patchValue(address),
      error: (err) => {
        if (this.appState.isBrowser) {
          this.httpErrorService.onHttpError(err, 'Could not load your address');
        }
      },
    });

    this.storeType = this.appState.storeType;
    this.initShowUseShippingAddress();
    this.countryStateService.initStates();
  }

  initShowUseShippingAddress() {
    if (this.type === AddressType.BILLING && this.cartService.hasCartGotAccessoryItem()) {
      this.showUsingShippingAsBilling = true;
    } else {
      this.showUsingShippingAsBilling = false;
    }
  }

  onUseThisAddressClick() {
    this.isSaving = true;
    const verifyAddress: ShipEngineAddressDto = {
      name: `${this.form.controls.firstName.value} ${this.getMiddleName()} ${this.form.controls.lastName.value}`,
      company_name: this.form.controls.company.value,
      address_line1: this.form.controls.address1.value,
      address_line2: this.form.controls.address2.value,
      city_locality: this.form.controls.city.value,
      state_province: this.form.controls.state.value,
      postal_code: this.form.controls.zip.value,
      country_code: 'US',
    }

    this.addressService.verifyAddress(verifyAddress).subscribe({
      next: (res) => this.addressVerificationSuccess(res, verifyAddress),
      error: (err) => this.addressVerificationError(err)
    });
  }

  addressVerificationSuccess(res: ShipEngineAddressValidateResponse, userEnteredAddress: ShipEngineAddressDto) {
    if (res.status !== 'verified') {
      this.confirmationService.confirm({
        header: 'Problem with address',
        message: `${res.messages.map(m => m.message).join(', ')}. Please enter a valid address.`,
        accept: () => {},
        reject: () => {}
      });
      this.isSaving = false;
      return;
    }

    if (res.matched_address.address_line1 === userEnteredAddress.address_line1
      && res.matched_address.address_line2 === userEnteredAddress.address_line2
      && res.matched_address.city_locality === userEnteredAddress.city_locality
      && res.matched_address.state_province === userEnteredAddress.state_province
      && res.matched_address.postal_code === userEnteredAddress.postal_code
    ) {
      this.saveAddress(res.matched_address);
      return;
    }

    const dialog = this.dialogService.open(NgxAimVerifyAddressComponent, {
      data: res,
      header: 'Verify Address',
      width: '700px',
      contentStyle: { "max-height": "90%", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe((shouldContinue: boolean) => {
      if (shouldContinue) {
        this.saveAddress(res.matched_address);
      } else {
        this.isSaving = false;
      }
    });
  }

  addressVerificationError(err: any) {
    this.confirmationService.confirm({
      header: 'Problem with address',
      message: 'Could not connect to server. Please try again.',
      accept: () => {},
      reject: () => {}
    });
    this.isSaving = false;
  }

  saveAddress(matchedAddress: ShipEngineAddressDto) {
    const address: SaveAddressDto = {
      Name: matchedAddress.name,
      Company: this.form.controls.company.value,
      CellPhone: this.form.controls.phone.value,
      Address1: matchedAddress.address_line1,
      Address2: matchedAddress.address_line2 || '',
      City: matchedAddress.city_locality,
      State: matchedAddress.state_province,
      Zip: matchedAddress.postal_code,
      addressType: this.type,
    }

    this.checkoutService.saveAddress(address).subscribe({
      next: (address) => this.onSaveAddressSuccess(),
      error: (err) => this.onSaveAddressErorr(err),
    });
  }

  onSaveAddressSuccess() {
    this.isSaving = false;
    switch (this.checkoutType) {
      case CheckoutType.PURCHASE: {
        this.purchaseSaveAddressSuccess();
        break;
      }
      case CheckoutType.SHORT_TERM_RENTAL: {
        this.shortTermRentalSaveAddressSuccess();
        break;
      }
      case CheckoutType.CONTRACT: {
        this.contractSaveAddressSuccess();
        break;
      }
    }
  }

  purchaseSaveAddressSuccess() {
    const isFirearmStoreAndHasAccessoryItem = this.appState.storeType === StoreType.FIREARMS && this.cartService.hasCartGotAccessoryItem();
    const isFirearmStoreAndHasSerializedItem = this.appState.storeType === StoreType.FIREARMS && this.cartService.hasCartGotSerializedItem();
    const isGenericStore = this.appState.storeType === StoreType.GENERIC;

    if (this.type === AddressType.SHIPPING) {
      if (isGenericStore) {
        this.checkoutService.getDeliveryOptions && this.checkoutService.getDeliveryOptions(ItemInventoryType.ANY);
      } else if (isFirearmStoreAndHasAccessoryItem) {
        this.checkoutService.getDeliveryOptions && this.checkoutService.getDeliveryOptions(ItemInventoryType.ACCESSORY);
      }

      this.checkoutService.setTabIsDisabled(CheckoutTabNames.BILLING_ADDRESS, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.BILLING_ADDRESS);
    } else if (isFirearmStoreAndHasSerializedItem) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.FFL_SELECTOR, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.FFL_SELECTOR);
    } else if (isGenericStore || isFirearmStoreAndHasAccessoryItem) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.DELIVERY_OPTIONS, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.DELIVERY_OPTIONS);
    } else if (this.appState.paymentType === PaymentType.NONE) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.PAYMENT, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.PAYMENT);
    }
  }

  shortTermRentalSaveAddressSuccess() {
    if (this.appState.paymentType === PaymentType.NONE) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.PAYMENT, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.PAYMENT);
    }
  }

  contractSaveAddressSuccess() {
    if (this.appState.paymentType === PaymentType.NONE) {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
    } else {
      this.checkoutService.setTabIsDisabled(CheckoutTabNames.PAYMENT, false);
      this.checkoutService.setTabIndexByHeader(CheckoutTabNames.PAYMENT);
    }
  }

  onSaveAddressErorr(err: any) {
    this.isSaving = false;
    this.httpErrorService.onHttpError(err, 'Please try again');
  }

  getMiddleName(): string {
    if (this.form.controls.middleName.value) {
      return this.form.controls.middleName.value;
    } else if (this.appState.storeType === StoreType.FIREARMS) {
      return 'NMN';
    } else {
      return '';
    }
  }

  onUseShippingForBillingChange($event: InputSwitchChangeEvent) {
    if ($event.checked) {
      this.isLoading = true;
      this.checkoutService.getAddress(AddressType.SHIPPING).subscribe({
        next: (address) => this.afterLoadShippingForBilling(address),
        error: (err) => {
          this.httpErrorService.onHttpError(err, 'Could not load your address');
          this.isLoading = false;
        },
      });
    } else {
      this.form.reset();
    }
  }

  afterLoadShippingForBilling(address: UserAddress) {
    this.form.patchValue(address);
    this.isLoading = false;
  }
}