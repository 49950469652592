import { Component, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FormsModule } from '@angular/forms';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxAimAdminEditItemComponent } from './edit-item/ngx-aim-admin-edit-item.component';
import { NgxAimAdminPrimengModule } from '../ngx-aim-admin.primeng.module';
import { AppLazyLoadEvent } from '../../../models';
import { HttpErrorService } from '../../../services';
import { MatchModesPrime } from '../../../enums';
import { NgxAimAdminItemsService } from './ngx-aim-admin-admin-items.service';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-admin-items',
  standalone: true,
  imports: [FormsModule, NgxAimAdminPrimengModule, AsyncPipe, NgClass, CurrencyPipe],
  templateUrl: './ngx-aim-admin-items.component.html',
  styleUrls: ['./ngx-aim-admin-items.component.scss', '../admin.scss'],
  providers: [DialogService, ConfirmationService],
})
export class NgxAimAdminItemsComponent {
  @ViewChild('dt') table: Table | undefined;
  data$ = this.entityService.data$;
  isLoading$ = this.entityService.isLoading$;
  dataCount$ = this.entityService.dataCount$;
  lastLazyLoadEvent: AppLazyLoadEvent = {
    globalFilterFields: [],
    filters: {}
  };
  selectedOptions: string[] = [];
  isLoading = false;

  constructor(
    private entityService: NgxAimAdminItemsService,
    private dialogService: DialogService,
    private httpErrorService: HttpErrorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  onLazyLoad($event: TableLazyLoadEvent) {
    this.lastLazyLoadEvent = $event;
    const lazyLoadEvent: AppLazyLoadEvent = Object.assign({
      withDeleted: this.selectedOptions.includes('withDeleted'),
      globalFilterFields: this.table?.globalFilterFields || [],
    }, $event);

    this.entityService.onFilterEvent(lazyLoadEvent);
  }

  applyFilterGlobal($event: Event) {
    this.table?.filterGlobal(($event.target as HTMLInputElement).value, MatchModesPrime.CONTAINS);
  }

  onSyncClick(event: Event): void {
    this.confirmationService.confirm({
      key: 'onSyncClick',
      target: event.target!,
      message: `This action will sync every item. Depending on the amount of items it make take a long time to complete. \r\n\r\nDo you wish to continue?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sync();
      }
    });
  }

  sync() {
    this.isLoading = true;
    this.entityService.sync().subscribe({
      next: () => this.onSyncSuccess(),
      error: (err) => this.onSyncError(err, 'Could not sync items'),
    });
  }

  onSyncSuccess() {
    this.isLoading = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Confirmed',
      detail: `Items syncing. This might take a while.`,
      key: 'app-toast',
      life: 5000
    });
    this.onLazyLoad(this.lastLazyLoadEvent);
  }

  onSyncError(err: any, summary: string) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, summary);
  }

  openEntity(entityId?: number) {
    const dialog = this.dialogService.open(NgxAimAdminEditItemComponent, {
      data: entityId,
      header: 'Item',
      width: '700px',
      contentStyle: { "max-height": "580px", "overflow": "auto" },
      dismissableMask: true
    });
    dialog.onClose.subscribe(() => {
      this.onLazyLoad(this.lastLazyLoadEvent);
    });
  }
}
