import { DestroyRef,ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AppStateService, CustomerService, HttpErrorService, PaymentService } from '../../services';
import { PaymentStatus, PaymentType, PublicRoutes, TransactionType } from '../../enums';
import { UpdateCCTokenResponse } from '../../models';

/** when extending this class be sure to implement ngOnInit,
 * @ViewChild('paymentForm', { read: ViewContainerRef })
 * override paymentFormRef!: ViewContainerRef; 
 */
export abstract class NgxAimEditCardClass {
  // ngOnInit() {
  //   this.init();
  // }

  route = inject(ActivatedRoute);
  router = inject(Router);
  appState = inject(AppStateService);
  customerService = inject(CustomerService);
  paymentService = inject(PaymentService);
  httpErrorService = inject(HttpErrorService);
  messageService = inject(MessageService);

  paymentFormRef!: ViewContainerRef;
  params$ = this.route.params.pipe(
    distinctUntilChanged(),
    takeUntilDestroyed(),
  );

  AcctType: string | undefined;
  destroyRef = inject(DestroyRef);
  isLoading = false;

  init() {
    this.params$.subscribe((params) => {
      if (!params['AcctType']) {
        this.router.navigateByUrl(`/${PublicRoutes.CUSTOMER}/${PublicRoutes.MANAGE_CREDIT_CARD}`);
      } else {
        this.AcctType = params['AcctType'];
        this.initPaymentForm(this.appState.paymentType);
      }
    });
  }

  initPaymentForm(type: PaymentType) {
    this.paymentService.cardData.type = type;
    switch (type) {
      case PaymentType.AUTHORIZENET: {
        import('../payment/authorizenet/ngx-aim-authorizenet.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimAuthorizenetComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.GLOBALPAY:
      case PaymentType.GLOBALPAY_AUTH_ONLY: {
        import('../payment/globalpay/ngx-aim-globalpay.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimGlobalpayComponent);
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
      case PaymentType.TSYS_AUTH_ONLY: {
        import('../payment/tsys/ngx-aim-tsys.component').then(c => {
          const comp = this.paymentFormRef.createComponent(c.NgxAimTsysComponent);
          comp.instance.transactionType = TransactionType.CONTRACT;
          comp.instance.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isSuccess) => {
            this.onCardSuccess();
          });
        });
        break;
      }
    }
  }

  onCardSuccess() {
    this.isLoading = true;
    if (!this.AcctType) {
      return;
    }

    this.customerService.updateCCToken(this.paymentService.cardData, this.AcctType).subscribe({
      next: (res) => this.updateSuccess(res),
      error: (err) => this.onFailure(err),
    });
  }

  updateSuccess(res: UpdateCCTokenResponse) {
    this.isLoading = false;
    if (res.paymentStatus === PaymentStatus.APPROVED) {
      this.router.navigateByUrl(`/${PublicRoutes.CUSTOMER}/${PublicRoutes.MANAGE_CREDIT_CARD}`);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Could not update credit card',
        key: 'app-toast'
      });
    }
  }

  onFailure(err: any) {
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, 'Could not update card');
  }
}