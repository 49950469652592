import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../ngx-aim.service';
import { AppLazyLoadEvent, FindAllResult, LineItem, Order, PaymentLog } from '../../../models';
import { Observable } from 'rxjs';
import { PaymentStatus } from '../../../enums';
import { GenericSearchService, HttpErrorService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminOrderService extends GenericSearchService<Order> {

  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/aim/orders`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService
  ) {
    super(_httpErrorService, 'Orders');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<Order>> {
    return this.http.post<FindAllResult<Order>>(`${this.baseUrl}/all`, dto);
  }

  findOne(id: number): Observable<Order> {
    return this.http.get<Order>(`${this.baseUrl}/order/${id}`);
  }

  save(dto: any): Observable<Order> {
    return this.http.post<Order>(`${this.baseUrl}`, dto);
  }

  getPaymentLog(id: number): Observable<PaymentLog[]> {
    return this.http.get<PaymentLog[]>(`${this.baseUrl}/order-payment-log/${id}`);
  }

  refundOrder(id: number): Observable<{ status: PaymentStatus }> {
    return this.http.post<{ status: PaymentStatus }>(`${this.baseUrl}/order-refund/${id}`, {});
  }

  getLineItems(id: number): Observable<LineItem[]> {
    return this.http.get<LineItem[]>(`${this.baseUrl}/order-line-items/${id}`);
  }

  completeOrder(id: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/order-complete/${id}`, {});
  }
}
