<p-blockUI [target]="editor" [blocked]="isLoading" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<ngx-aim-p-blockable-div #editor>
  <div id="post-container">
    <form [formGroup]="form" class="p-fluid p-formgrid grid">
      <div class="field col-6">
        <span style="color: gray;">Image</span>
        <p-fileUpload url="api/v1/admin/app-post/image"
                      name="src"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content">
            @if (form.controls.image.value) {
              <img class="mt-1 post-image" [src]="'files/' + form.controls.image.value"/>
            }
          </ng-template>
        </p-fileUpload>
      </div>
      <div class="field col-6"></div>
      <div class="field col-6">
        <span class="p-float-label">
          <input pInputText id="title" formControlName="title" />
          <label htmlFor="title">Title</label>
        </span>
      </div>
      <div class="field col-6">
        <span class="p-float-label">
          <input pInputText id="sub-title" formControlName="subTitle" />
          <label htmlFor="sub-title">Sub Title</label>
        </span>
      </div>
      <div class="field col-6">
        <span class="p-float-label">
          <input pInputText id="category" formControlName="category" />
          <label htmlFor="category">Category</label>
        </span>
      </div>
      <div class="field col-12">
        <span class="p-float-label">
          <quill-editor class="editor" formControlName="body"></quill-editor>
        </span>
      </div>
    </form>
    <button pButton pRipple label="Save" class="p-button-primary mr-2" (click)="onSaveClick()"></button>
  </div>
</ngx-aim-p-blockable-div>
<p-dialog header="Saving"
          [modal]="true"
          [(visible)]="isSaving"
          [style]="{ width: '25rem' }"
          [closeOnEscape]="false"
          [closable]="false">
  <span class="p-text-secondary block mb-5">{{uploadProgress + downloadProgress}}%</span>
</p-dialog>
