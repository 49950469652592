import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { AppUploadEvent, CarouselSlide } from '../../../../../models';
import { HttpErrorService } from '../../../../../services';
import { NgxAimAdminCarouselService } from '../ngx-aim-admin-carousel.service';

@Component({
  selector: 'ngx-aim-admin-edit-carousel',
  standalone: true,
  imports: [ReactiveFormsModule, FileUploadModule],
  templateUrl: './ngx-aim-admin-edit-carousel.component.html',
  styleUrls: ['./ngx-aim-admin-edit-carousel.component.scss', '../../../admin.scss'],
})
export class NgxAimAdminEditCarouselComponent implements OnInit {
  headers: any;
  isLoading = false;
  isSaving = false;

  form = this.fb.group({
    id: this.fb.control<number | null>(null),
    carouselName: this.fb.control<string>(''),
    name: this.fb.control<string>(''),
    position: this.fb.control<number>(-1),
    src: this.fb.control<string>(''),
    mobileSrc: this.fb.control<string>(''),
  });

  constructor(
    private entityService: NgxAimAdminCarouselService,
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private httpErrorService: HttpErrorService,
  ) {}

  ngOnInit() {
    if (this.config.data.entityId) {
      this.getEntity(this.config.data.entityId);
      this.headers = { id: this.config.data.entityId };
    }
    this.form.patchValue({ carouselName: this.config.data.carouselName });
  }

  getEntity(entityId: number) {
    this.isLoading = true;
    this.entityService.findOne(entityId).subscribe({
      next: (entity) => this.setFormData(entity),
      error: (err) => this.onError(err, 'Could not retrieve carousel slide')
    });
  }

  setFormData(entity: CarouselSlide) {
    this.isLoading = false;
    this.form.patchValue(entity);
  }

  onSave() {
    this.isSaving = true;
    this.entityService.save(this.form.getRawValue()).subscribe({
      next: (entity) => this.onSuccess(entity),
      error: (err) => this.onError(err, 'Could not save carousel slide')
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onError(err: any, message: string) {
    this.isSaving = false;
    this.isLoading = false;
    this.httpErrorService.onHttpError(err, message);
  }

  onSuccess(entity: CarouselSlide) {
    this.setFormData(entity);
    this.isSaving = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Carousel Slide saved',
      key: 'app-toast'
    });
  }

  onUpload(event: AppUploadEvent, isMobile: boolean) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Image Uploaded', key: 'app-toast' });
    if (isMobile) {
      this.form.patchValue({ mobileSrc: event.originalEvent.body.url });
    } else {
      this.form.patchValue({ src: event.originalEvent.body.url });
    }
  }

  onUploadError(event: any) {
    this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Image could not be uploaded', key: 'app-toast' });
  }
}
