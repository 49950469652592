import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { NgxAimViewOrderClass } from './ngx-aim-view-order.class';

@Component({
  selector: 'ngx-aim-view-order',
  standalone: true,
  imports: [ReactiveFormsModule, ProgressSpinnerModule, DividerModule, MenubarModule, InputTextModule],
  templateUrl: './ngx-aim-view-order.component.html',
  styleUrl: './ngx-aim-view-order.component.css',
  providers: [DatePipe],
})
export class NgxAimViewOrderComponent extends NgxAimViewOrderClass implements OnInit {
  ngOnInit() {
    this.init();
  }
}
