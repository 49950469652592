<p-blockUI [target]="gpPaymentForm" [blocked]="isLoading" styleClass="border-round-lg">
  <p-progressSpinner></p-progressSpinner>
</p-blockUI>
<ngx-aim-p-blockable-div #gpPaymentForm>
  <div >
    <div class="mb-2">
      <img src="images/others/accepted-cards.png" alt="accepted cards">
    </div>
    <form id="payment-form" [ngStyle]="{'display': cardData ? 'none' : 'block'}">
      Card Number:
      <div id="card-number"></div>
      Expiration:
      <div id="card-expiration"></div>
      CVV:
      <div id="card-cvv"></div>
      <div id="submit" [ngClass]="{'disabled': !isSubmitButtonEnabled()}"></div>
    </form>
  </div>
</ngx-aim-p-blockable-div>
@if (cardData?.card) {
  <div>
    <div class="ml-2 mb-2">
      Added {{cardData?.card?.type}} ending in: {{cardData?.card?.masked_card_number?.slice(12)}}
    </div>
    <p-button (onClick)="onChangeCardClick()" label="Change Credit Card" class="ml-2"></p-button>
  </div>
}
<p-dialog header="Please fix the following problems" [(visible)]="displayErrorDialog">
  @for (error of errors; track error) {
    <p>
      {{error}}
    </p>
  }
</p-dialog>
