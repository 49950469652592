<div class="col-12">
  <form [formGroup]="form" class="p-fluid p-formgrid grid dialog-form">
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <input pInputText id="Description" formControlName="Description" pTooltip="Modify in AIM" />
        <label htmlFor="Description">Description</label>
      </span>
    </div>
    <div class="field col-12 md:col-6">
      <span class="p-float-label">
        <p-dropdown [options]="dropDownOptions" formControlName="showOnMainNav" placeholder="Show On Main Navigation"  inputId="showOnMainNav"></p-dropdown>
        <label for="showOnMainNav">Show On Main Navigation</label>
    </span>
    </div>
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <input pInputText id="webTitle" formControlName="webTitle" />
        <label htmlFor="webTitle">Title</label>
      </span>
    </div>
    <div class="field col-12 md:col-12">
      <span class="p-float-label">
        <input pInputText id="shortDescription" formControlName="shortDescription" />
        <label htmlFor="shortDescription">Short Description</label>
      </span>
    </div>
    <h3>Sub categories</h3>
    <div class="field col-12 md:col-12">
      <p-listbox [options]="subCategories" optionLabel="Description" [filter]="true" (onClick)="onSubClick($event)">
        <ng-template let-sub pTemplate="item">
          <div class="flex align-items-center gap-2">
              <div>{{ sub.Description }} - {{sub.showOnMainNav}}</div>
          </div>
      </ng-template>
      </p-listbox>
    </div>
    <div class="field col-12 md:col-12">
      <span>
        <span>Image</span>
        <p-fileUpload url="api/v1/admin/aim/category/image"
                      name="thumbSrc"
                      accept="image/*"
                      [multiple]="false"
                      [headers]="headers"
                      (onUpload)="onUpload($event)"
                      (onError)="onUploadError($event)">
          <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        @if (form.controls.thumbSrc.value) {
          <div>
            <img class="mt-1" [src]="'files/' + form.controls.thumbSrc.value" width="100%" />
          </div>
        }
      </span>
    </div>
  </form>
  <div class="flex justify-content-end">
    <p-button label="Save"
              icon="pi pi-check"
              styleClass="p-button-text"
              [loading]="isSaving"
              [disabled]="form.invalid"
              (onClick)="onSave()">
    </p-button>
    <p-button label="Cancel"
              icon="pi pi-times"
              styleClass="p-button-text"
              [disabled]="isSaving"
              (onClick)="onCancel()">
    </p-button>
  </div>
</div>
@if (isLoading) {
  <div class="progress-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
}
