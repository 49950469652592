import { Component, OnInit } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { BlockUIModule } from 'primeng/blockui';
import { NgxAimPBlockableDivComponent } from '../../p-blockable-div/ngx-aim-p-blockable-div.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxAimFflSelectorClass } from './ngx-aim-ffl-selector.class';

@Component({
  selector: 'ngx-aim-ffl-selector',
  standalone: true,
  imports: [
    TooltipModule,
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    ListboxModule,
    FormsModule,
    BlockUIModule,
    NgxAimPBlockableDivComponent,
    ProgressSpinnerModule,
    ButtonModule,
  ],
  templateUrl: './ngx-aim-ffl-selector.component.html',
  styleUrls: ['./ngx-aim-ffl-selector.component.css'],
  providers: [DialogService]
})
export class NgxAimFflSelectorComponent extends NgxAimFflSelectorClass implements OnInit {
  ngOnInit() {
    this.init();
  }
}
