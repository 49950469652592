import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxAimService } from '../../../../../ngx-aim.service';
import {
  AppLazyLoadEvent,
  FindAllResult,
  ItemInventoryType,
  ShipEngineCarrier,
  ShipEngineCarrierService,
  ShipEngineLabel
} from '../../../../../models';
import { Observable } from 'rxjs';
import { GenericSearchService, HttpErrorService } from '../../../../../services';

@Injectable({
  providedIn: 'root'
})
export class NgxAimAdminShipEngineService extends GenericSearchService<ShipEngineCarrier> {
  private baseUrl = `${this.ngxAimService.getApiUrl()}/admin/shipping/ship-engine`;

  constructor(
    private http: HttpClient,
    private ngxAimService: NgxAimService,
    private _httpErrorService: HttpErrorService,
  ) {
    super(_httpErrorService, 'Ship Engine');
  }

  findAll(dto: AppLazyLoadEvent): Observable<FindAllResult<ShipEngineCarrier>> {
    return this.http.post<FindAllResult<ShipEngineCarrier>>(`${this.baseUrl}/carriers/all`, dto);
  }

  updateCarriers(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/carriers`, {});
  }

  updateServices(carrier_id: string, services: ShipEngineCarrierService[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/carriers/services`, { carrier_id, services });
  }

  createLabel(orderNumber: number, itemType: ItemInventoryType): Observable<ShipEngineLabel> {
    return this.http.post<ShipEngineLabel>(`${this.baseUrl}/create-label`, { orderNumber, itemType });
  }
}
