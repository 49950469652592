import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CatForDisplay } from '../../../../models';
import { NgxAimCategoryFilterClass } from './ngx-aim-category-filter.class';

@Component({
  selector: 'ngx-aim-category-filter',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './ngx-aim-category-filter.component.html',
  styleUrls: ['./ngx-aim-category-filter.component.scss']
})
export class NgxAimCategoryFilterComponent extends NgxAimCategoryFilterClass {
  @Input()
  override categories: CatForDisplay[] = [];
}
