import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  NgZone,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthnetCardData, AuthnetConfig, AuthorizenetCardResponse } from './authorizenet.model';
import { NgxAimAuthorizenetService } from './ngx-aim-authorizenet.service';
import { GenericCheckoutService, HttpErrorService, PaymentService } from '../../../services';
import { ButtonModule } from 'primeng/button';
import { CheckoutTabNames } from '../../../enums';

declare const window: any;

@Component({
  selector: 'ngx-aim-authorizenet',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './ngx-aim-authorizenet.component.html',
  styleUrls: ['./ngx-aim-authorizenet.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxAimAuthorizenetComponent implements OnInit {
  @Input({ required: true }) checkoutService: GenericCheckoutService | undefined;
  @Output() success = new EventEmitter<boolean>();

  authnetConfig: AuthnetConfig = {
    acceptUrl: '',
    apiLoginID: '',
    clientKey: '',
  }
  cardData: AuthnetCardData = {
    dataDescriptor: '',
    dataValue: '',
    cardNumber: '',
    expireMonth: '',
    expireYear: '',
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authnetService: NgxAimAuthorizenetService,
    private httpErrorService: HttpErrorService,
    private changeRef: ChangeDetectorRef,
    private zone: NgZone,
    private paymentService: PaymentService,
  ) {}

  ngOnInit() {
    this.initConfig();
  }

  initConfig() {
    this.authnetService.getConfig().subscribe({
      next: (config) => this.afterConfigInit(config),
      error: (err) => this.httpErrorService.onHttpError(err),
    });
  }

  afterConfigInit(config: AuthnetConfig) {
    this.authnetConfig = config;
    this.initAuthorizenet();
  }

  initAuthorizenet() {
    const headerEl = this.document.getElementsByTagName('head')[0];
    const element = this.document.createElement('script');
    element.src = this.authnetConfig.acceptUrl;
    element.type = 'text/javascript';
    headerEl.appendChild(element);

    // Accept.js will error unless we make this function global
    window.authNetResponseHandler = (response: any) => {
      return this.authNetResponseHandler(response);
    }
    this.changeRef.detectChanges();
  }

  authNetResponseHandler(response: AuthorizenetCardResponse) {
    if (response.messages.resultCode === 'Error') {
      let i = 0;
      const errors: string[] = [];
      while (i < response.messages.message.length) {
        console.log(
          response.messages.message[i].code + ': ' +
          response.messages.message[i].text
        );
        errors.push(response.messages.message[i].text);
        i++;
      }

      alert(`Problem with credit card. ${errors.join(', ')}`);
      return;
    }

    const expiredParts = response.encryptedCardData.expDate.split('/');
    this.cardData = {
      dataDescriptor: response.opaqueData.dataDescriptor,
      dataValue: response.opaqueData.dataValue,
      cardNumber: response.encryptedCardData.cardNumber,
      expireMonth: expiredParts[0],
      expireYear: expiredParts[1],
    }
    this.paymentService.cardData.authorizenet = this.cardData;
    this.changeRef.detectChanges();
    this.zone.run(() => {
      if (this.checkoutService) {
        this.checkoutService.setTabIsDisabled(CheckoutTabNames.OVERVIEW, false);
        this.checkoutService.setTabIndexByHeader(CheckoutTabNames.OVERVIEW);
      } else {
        this.success.emit(true);
      }
    });
  }
}
