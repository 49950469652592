import { Component, Input, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { HasImagePipe } from '../../../pipes';
import { DataViewModule } from 'primeng/dataview';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { NgxAimOverviewClass } from './ngx-aim-overview.class';
import { CurrencyPipe, NgClass } from '@angular/common';

@Component({
  selector: 'ngx-aim-overview',
  standalone: true,
  imports: [
    ButtonModule,
    HasImagePipe,
    DataViewModule,
    DividerModule,
    DialogModule,
    CurrencyPipe,
    NgClass,
  ],
  templateUrl: './ngx-aim-overview.component.html',
  styleUrls: ['./ngx-aim-overview.component.css']
})
export class NgxAimOverviewComponent extends NgxAimOverviewClass implements OnInit {
  @Input({ required: true })
  override completeUrl: string = '';

  ngOnInit() {
    this.init();
  }
}
