Rental pricing
<div class="flex flex-row flex-wrap gap-5 rate-wrapper">
  @if (isLoading) {
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
  }
  @for (rate of rates; track rate) {
    <div class="rate">
      <div class="flex-1 flex-column align-content-center">
        <h2 class="text-center m-0">{{rate.Amt | currency}}</h2>
        <div class="text-center">{{rate.Desc}}</div>
      </div>
    </div>
  }
</div>
