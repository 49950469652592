import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { inject } from '@angular/core';
import { AppStateService, UserService } from '../../services';
import { passwordsMatchValidator } from '../../validators';
import { PublicRoutes } from '../../enums';

/** when extending this class be sure to implement ngOnInit */
export abstract class NgxAimResetPasswordClass {
  // ngOnInit(): void {
  //   this.init()
  // }

  routes = PublicRoutes;
  fb = inject(NonNullableFormBuilder);
  userService = inject(UserService);
  route = inject(ActivatedRoute);
  appState = inject(AppStateService);

  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
    token: '',
  }, { validators: [passwordsMatchValidator] });
  isLoading = false;
  isDialogVisible = false;
  error = '';

  init(): void {
    if (this.appState.isBrowser) {
      this.getToken();
    }
  }

  getToken() {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (params['token']) {
        this.form.patchValue({ token });
      } else {
        this.onInvalidToken();
      }
    });
  }

  onInvalidToken() {
    this.error = 'Invalid reset link'
    this.form.disable();
  }

  onSubmit() {
    if (!this.form.value.password) {
      this.form.controls.password.markAsTouched();
      return;
    }

    if (!this.form.value.confirmPassword) {
      this.form.controls.confirmPassword.markAllAsTouched();
      return;
    }

    this.error = '';
    this.isLoading = true;
    this.userService.resetPassword(this.form.getRawValue()).subscribe({
      next: () => this.onSuccess(),
      error: (err) => this.onError(err)
    });
  }

  onSuccess() {
    this.isLoading = false;
    this.isDialogVisible = true;
  }

  onError(err: any) {
    this.isLoading = false;
    this.error = err.text || err.error?.message || err.error?.error || err.message || 'Something went wrong';
  }

  get fc() { return this.form.controls; }
}