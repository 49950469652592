import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { Router } from '@angular/router';
import { NgxAimAdminRoutes } from '../ngx-aim-admin-admin.routes';

@Component({
  selector: 'ngx-aim-admin-contracts',
  standalone: true,
  imports: [ButtonModule, ToolbarModule],
  templateUrl: './ngx-aim-admin-contracts.component.html',
  styleUrls: ['./ngx-aim-admin-contracts.component.scss', '../admin.scss']
})
export class NgxAimAdminContractsComponent {
  constructor(private router: Router) {}

  onFormInputsClick() {
    this.router.navigate([NgxAimAdminRoutes.ROOT, NgxAimAdminRoutes.CONTRACTS, NgxAimAdminRoutes.FORM_IMPUTS]);
  }
}
